(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.aq.V === region.aw.V)
	{
		return 'on line ' + region.aq.V;
	}
	return 'on lines ' + region.aq.V + ' through ' + region.aw.V;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList === 'function' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.a9,
		impl.bm,
		impl.bk,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		y: func(record.y),
		ar: record.ar,
		an: record.an
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.y;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.ar;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.an) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.a9,
		impl.bm,
		impl.bk,
		function(sendToApp, initialModel) {
			var view = impl.bn;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.a9,
		impl.bm,
		impl.bk,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.ap && impl.ap(sendToApp)
			var view = impl.bn;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.a_);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.bl) && (_VirtualDom_doc.title = title = doc.bl);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.bd;
	var onUrlRequest = impl.be;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		ap: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.aL === next.aL
							&& curr.aA === next.aA
							&& curr.aI.a === next.aI.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		a9: function(flags)
		{
			return A3(impl.a9, flags, _Browser_getUrl(), key);
		},
		bn: impl.bn,
		bm: impl.bm,
		bk: impl.bk
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { a7: 'hidden', a$: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { a7: 'mozHidden', a$: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { a7: 'msHidden', a$: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { a7: 'webkitHidden', a$: 'webkitvisibilitychange' }
		: { a7: 'hidden', a$: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		aR: _Browser_getScene(),
		aV: {
			aj: _Browser_window.pageXOffset,
			ak: _Browser_window.pageYOffset,
			Q: _Browser_doc.documentElement.clientWidth,
			I: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		Q: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		I: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			aR: {
				Q: node.scrollWidth,
				I: node.scrollHeight
			},
			aV: {
				aj: node.scrollLeft,
				ak: node.scrollTop,
				Q: node.clientWidth,
				I: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			aR: _Browser_getScene(),
			aV: {
				aj: x,
				ak: y,
				Q: _Browser_doc.documentElement.clientWidth,
				I: _Browser_doc.documentElement.clientHeight
			},
			a0: {
				aj: x + rect.left,
				ak: y + rect.top,
				Q: rect.width,
				I: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.f) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.h),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.h);
		} else {
			var treeLen = builder.f * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.i) : builder.i;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.f);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.h) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.h);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{i: nodeList, f: (len / $elm$core$Array$branchFactor) | 0, h: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {ay: fragment, aA: host, aG: path, aI: port_, aL: protocol, aN: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$element = _Browser_element;
var $author$project$Main$Infinite = 0;
var $author$project$Main$Neutral = 1;
var $author$project$Main$Start = 0;
var $author$project$Quiz$Binominal = 1;
var $author$project$BelgianBirds$data = _List_fromArray(
	[
		{a: 'Prunella modularis', b: '59', c: 'Par Karstenderivative work — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=7023601', d: 'Accenteur mouchet'},
		{a: 'Alauda arvensis', b: '60', c: 'Par Daniel Pettersson — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=1722926', d: 'Alouette des champs'},
		{a: 'Accipiter gentilis', b: '61', c: 'Par Cymbella — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=21467538', d: 'Autour des palombes'},
		{a: 'Recurvirostra avosetta', b: '62', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=10610115', d: 'Avocette élégante'},
		{a: 'Pandion haliaetus', b: '63', c: 'Par Yathin S Krishnappa — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=21376478', d: 'Balbuzard pêcheur'},
		{a: 'Loxia curvirostra', b: '64', c: 'Par User:Aelwyn — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=2230007', d: 'Bec-croisé des sapins'},
		{a: 'Scolopax rusticola', b: '65', c: 'Par Ronald Slabke — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=5703078', d: 'Bécasse des bois'},
		{a: 'Calidris alpina', b: '66', c: 'Par Jevgenijs Slihto, CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=42966339', d: 'Bécasseau variable'},
		{a: 'Gallinago gallinago', b: '67', c: 'Par Pierre DalousLieu — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=21528551', d: 'Bécassine des marais'},
		{a: 'Motacilla cinerea', b: '68', c: 'Par Lip Kee Yap — Flickr: Grey wagtail, CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=14637693', d: 'Bergeronnette des ruisseaux'},
		{a: 'Motacilla alba', b: '69', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=3800685', d: 'Bergeronnette grise'},
		{a: 'Motacilla flava', b: '70', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=6685421', d: 'Bergeronnette printanière'},
		{a: 'Branta canadensis', b: '71', c: 'Par Connormah — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=27394270', d: 'Bernache du Canada'},
		{a: 'Pyrrhula pyrrhula', b: '72', c: 'Par Francis C. Franklin — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=37675952', d: 'Bouvreuil pivoine'},
		{a: 'Emberiza schoeniclus', b: '73', c: 'Par Gidzy, CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=6447232', d: 'Bruant des roseaux'},
		{a: 'Emberiza citrinella', b: '74', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=3986639', d: 'Bruant jaune'},
		{a: 'Circus aeruginosus', b: '75', c: 'Par Sumeet Moghe — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=49477506', d: 'Busard des roseaux'},
		{a: 'Circus cyaneus', b: '76', c: 'Par Katy Prairie Conservancy — https://commons.wikimedia.org/w/index.php?curid=5100533', d: 'Busard Saint-Martin'},
		{a: 'Buteo buteo', b: '77', c: 'Par Marek Szczepanek — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=90587', d: 'Buse variable'},
		{a: 'Botaurus stellaris', b: '78', c: 'Par Marek Szczepanek — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=63238', d: 'Butor étoilé'},
		{a: 'Anas platyrhynchos', b: '79', c: 'Par Richard Bartz — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=6449086', d: 'Canard colvert'},
		{a: 'Anas acuta', b: '80', c: 'Par Adam Kumiszcza — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=17692763', d: 'Canard pilet'},
		{a: 'Anas clypeata', b: '81', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=15010945', d: 'Canard souchet'},
		{a: 'Nucifraga caryocatactes', b: '82', c: 'Par MurrayBHenson — https://commons.wikimedia.org/w/index.php?curid=3708573', d: 'Cassenoix moucheté'},
		{a: 'Carduelis carduelis', b: '83', c: 'Par Pierre Dalous — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=22525310', d: 'Chardonneret élégant'},
		{a: 'Tringa ochropus', b: '84', c: 'CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=93990', d: 'Chevalier culblanc'},
		{a: 'Tringa totanus', b: '85', c: 'Par Frank Vassen — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=17063128', d: 'Chevalier gambette'},
		{a: 'Actitis hypoleucos', b: '86', c: 'Par Marek Szczepanek — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=63227', d: 'Chevalier guignette'},
		{a: 'Athene noctua', b: '87', c: 'Par Tony Wills — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=36442532', d: 'Chevêche d\'Athéna'},
		{a: 'Coloeus monedula', b: '88', c: 'Par Darkone — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=665741', d: 'Choucas des tours'},
		{a: 'Strix aluco', b: '89', c: 'Par K.-M. Hansche — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=775782', d: 'Chouette hulotte'},
		{a: 'Ciconia ciconia', b: '90', c: 'Par Dcabrilo — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=4675672', d: 'Cigogne blanche'},
		{a: 'Ciconia nigra', b: '91', c: 'Par Chris EasonLieu — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=6489838', d: 'Cigogne noire'},
		{a: 'Cinclus cinclus', b: '92', c: 'Par Mark Medcalf — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=15739681', d: 'Cincle plongeur'},
		{a: 'Corvus frugilegus', b: '93', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=19133958', d: 'Corbeau freux'},
		{a: 'Corvus corone', b: '94', c: 'Par Loz (L. B. Tettenborn) — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=2380746', d: 'Corneille noire'},
		{a: 'Cuculus canorus', b: '95', c: 'Par Vogelartinfoderivative work — GFDL 1.2, https://commons.wikimedia.org/w/index.php?curid=16077960', d: 'Coucou gris'},
		{a: 'Numenius arquata', b: '96', c: 'Par Ken Billington — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=12307666', d: 'Courlis cendré'},
		{a: 'Cygnus cygnus', b: '97', c: 'Par Rhion — https://commons.wikimedia.org/w/index.php?curid=450423', d: 'Cygne chanteur'},
		{a: 'Cygnus olor', b: '98', c: 'Par Sanchezn — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=3019282', d: 'Cygne tuberculé'},
		{a: 'Tyto alba', b: '99', c: 'Par Peter Trimming — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=15975742', d: 'Effraie des clochers'},
		{a: 'Accipiter nisus', b: '100', c: 'Par Meneer Zjeroen — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=8528830', d: 'Épervier d\'Europe'},
		{a: 'Sturnus vulgaris', b: '101', c: 'Par Marek Szczepanek — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=12866593', d: 'Étourneau sansonnet'},
		{a: 'Phasianus colchicus', b: '102', c: 'Par ChrisO — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=2367271', d: 'Faisan de Colchide'},
		{a: 'Falco tinnunculus', b: '103', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=6542109', d: 'Faucon crécerelle'},
		{a: 'Falco subbuteo', b: '104', c: 'Par Mike Prince - CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=62046802', d: 'Faucon hobereau'},
		{a: 'Falco peregrinus', b: '105', c: 'Par Carlos Delgado — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=38755194', d: 'Faucon pèlerin'},
		{a: 'Sylvia atricapilla', b: '106', c: 'Par Ron Knight — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=31193932', d: 'Fauvette à tête noire'},
		{a: 'Sylvia borin', b: '107', c: 'Par Biillyboy — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=11427509', d: 'Fauvette des jardins'},
		{a: 'Sylvia communis', b: '108', c: 'Par markkilner — CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=7607222', d: 'Fauvette grisette'},
		{a: 'Fulica atra', b: '109', c: 'Par Arpingstone — https://commons.wikimedia.org/w/index.php?curid=196325', d: 'Foulque macroule'},
		{a: 'Aythya ferina', b: '110', c: 'Par Neil Phillips — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=4287883', d: 'Fuligule milouin'},
		{a: 'Aythya fuligula', b: '111', c: 'Par Tim Felce — CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=27813802', d: 'Fuligule morillon'},
		{a: 'Gallinula chloropus', b: '112', c: 'Par Mathias Bigge — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=2686402', d: 'Gallinule poule-d\'eau'},
		{a: 'Bucephala clangula', b: '113', c: 'Par Adrian Pingstone — https://commons.wikimedia.org/w/index.php?curid=71030', d: 'Garrot à oeil d\'or'},
		{a: 'Garrulus glandarius', b: '114', c: 'Par Luc Viatour — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=5933620', d: 'Geai des chênes'},
		{a: 'Muscicapa striata', b: '115', c: 'Par Andrew Easton — https://commons.wikimedia.org/w/index.php?curid=251327', d: 'Gobemouche gris'},
		{a: 'Ficedula hypoleuca', b: '116', c: 'Par Ron Knight — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=26256664', d: 'Gobemouche noir'},
		{a: 'Larus argentatus', b: '117', c: 'Par Aiwok — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=11357929', d: 'Goéland argenté'},
		{a: 'Larus fuscus', b: '118', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=723467', d: 'Goéland brun'},
		{a: 'Larus canus', b: '119', c: 'Par Kathy2408 — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=58991576', d: 'Goéland cendré'},
		{a: 'Larus marinus', b: '120', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=10944563', d: 'Goéland marin'},
		{a: 'Luscinia svecica', b: '121', c: 'Par Alpo Roikola — CC BY 3.0, https://commons.wikimedia.org/w/index.php?curid=41343287', d: 'Gorgebleue à miroir'},
		{a: 'Bubo bubo', b: '122', c: 'Par Martin Mecnarowski — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=12686049', d: 'Grand-duc d\'Europe'},
		{a: 'Corvus corax', b: '123', c: 'Par David Hofmann — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=11342048', d: 'Grand Corbeau'},
		{a: 'Phalacrocorax carbo', b: '124', c: 'Par JJ Harrison — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=9761097', d: 'Grand Cormoran'},
		{a: 'Ardea alba', b: '125', c: 'Par User:Chmehl — CC BY 2.5, https://commons.wikimedia.org/w/index.php?curid=2261496', d: 'Grande Aigrette'},
		{a: 'Podiceps nigricollis', b: '126', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=33521634', d: 'Grèbe à cou noir'},
		{a: 'Tachybaptus ruficollis', b: '127', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=33505667', d: 'Grèbe castagneux'},
		{a: 'Podiceps cristatus', b: '128', c: 'CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=90629', d: 'Grèbe huppé'},
		{a: 'Certhia familiaris', b: '129', c: 'Par Eurasian_Treecreeper_in_the_snow.jpg — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=7991208', d: 'Grimpereau des bois'},
		{a: 'Certhia brachydactyla', b: '130', c: 'Par א (Aleph) — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=3906322', d: 'Grimpereau des jardins'},
		{a: 'Turdus viscivorus', b: '131', c: 'Par Neil Phillips — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=3663992', d: 'Grive draine'},
		{a: 'Turdus pilaris', b: '132', c: 'Par hedera.baltica — CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=43379234', d: 'Grive litorne'},
		{a: 'Turdus iliacus', b: '133', c: 'Par Ómar Runólfsson — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=15658059', d: 'Grive mauvis'},
		{a: 'Turdus philomelos', b: '134', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=22435708', d: 'Grive musicienne'},
		{a: 'Coccothraustes coccothraustes', b: '135', c: 'Par Martin Mecnarowski — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=12686196', d: 'Grosbec casse-noyaux'},
		{a: 'Grus grus', b: '136', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=39163967', d: 'Grue cendrée'},
		{a: 'Mergus merganser', b: '137', c: 'Par Toivo Toivanen &amp; Tiina Toppila — Public domain, https://commons.wikimedia.org/w/index.php?curid=73656', d: 'Harle bièvre'},
		{a: 'Mergellus albellus', b: '138', c: 'Public domain, https://commons.wikimedia.org/w/index.php?curid=73580', d: 'Harle piette'},
		{a: 'Ardea cinerea', b: '139', c: 'Par JJ Harrison — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=13352774', d: 'Héron cendré'},
		{a: 'Asio flammeus', b: '140', c: 'Par Caryn — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=11164107', d: 'Hibou des marais'},
		{a: 'Asio otus', b: '141', c: 'Par Mindaugas Urbonas — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=2690564', d: 'Hibou moyen-duc'},
		{a: 'Delichon urbicum', b: '142', c: 'Par Estormiz — CC0, https://commons.wikimedia.org/w/index.php?curid=19615759', d: 'Hirondelle de fenêtre'},
		{a: 'Riparia riparia', b: '143', c: 'CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=21446', d: 'Hirondelle de rivage'},
		{a: 'Ptyonoprogne rupestris', b: '144', c: 'Par Ptyonoprogne_rupestris_flock.jpg — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=10005953', d: 'Hirondelle de rochers'},
		{a: 'Hirundo rustica', b: '145', c: 'Par Malene Thyssen — CC BY 2.5, https://commons.wikimedia.org/w/index.php?curid=20612', d: 'Hirondelle rustique'},
		{a: 'Haematopus ostralegus', b: '146', c: 'Par Richard Bartz — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=27446701', d: 'Huîtrier pie'},
		{a: 'Hippolais polyglotta', b: '147', c: 'Par Frank Vassen — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=48850541', d: 'Hypolaïs polyglotte'},
		{a: 'Bombycilla garrulus', b: '148', c: 'Public domain, https://commons.wikimedia.org/w/index.php?curid=44050', d: 'Jaseur boréal'},
		{a: 'Carduelis cannabina', b: '149', c: 'Par Pierre Dalous — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=19100308', d: 'Linotte mélodieuse'},
		{a: 'Locustella naevia', b: '150', c: 'Par Stefan Hage, Birds.se — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=2544559', d: 'Locustelle tachetée'},
		{a: 'Oriolus oriolus', b: '151', c: 'Public domain, https://commons.wikimedia.org/w/index.php?curid=48853', d: 'Loriot d\'Europe'},
		{a: 'Alcedo atthis', b: '152', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=29351592', d: 'Martin-pêcheur d\'Europe'},
		{a: 'Apus apus', b: '153', c: 'Par Philip Heron — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=6907145', d: 'Martinet noir'},
		{a: 'Turdus torquatus', b: '154', c: 'Par Andreas Trepte, — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=17030554', d: 'Merle à plastron'},
		{a: 'Turdus merula', b: '155', c: 'Par PierreSelim — CC BY 3.0, https://commons.wikimedia.org/w/index.php?curid=15654080', d: 'Merle noir'},
		{a: 'Aegithalos caudatus', b: '156', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=6668932', d: 'Mésange à longue queue'},
		{a: 'Cyanistes caeruleus', b: '157', c: 'Par PeterRohrbeck — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=59394565', d: 'Mésange bleue'},
		{a: 'Poecile montanus', b: '158', c: 'Par Francis C. Franklin — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=31622934', d: 'Mésange boréale'},
		{a: 'Parus major', b: '159', c: 'Par Luc Viatour — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=4935129', d: 'Mésange charbonnière'},
		{a: 'Lophophanes cristatus', b: '160', c: 'Par Carlos Delgado — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=41082033', d: 'Mésange huppée'},
		{a: 'Periparus ater', b: '161', c: 'Par here — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=98232', d: 'Mésange noire'},
		{a: 'Poecile palustris', b: '162', c: 'Par Steffen Hannert — Copyrighted free use, https://commons.wikimedia.org/w/index.php?curid=98332', d: 'Mésange nonnette'},
		{a: 'Milvus migrans', b: '163', c: 'Par ThKraft — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=3808120', d: 'Milan noir'},
		{a: 'Milvus milvus', b: '164', c: 'Par Noel Reynolds — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=20461516', d: 'Milan royal'},
		{a: 'Passer domesticus', b: '165', c: 'Par Fir0002 — GFDL 1.2, https://commons.wikimedia.org/w/index.php?curid=7202303', d: 'Moineau domestique'},
		{a: 'Passer montanus', b: '166', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=5964819', d: 'Moineau friquet'},
		{a: 'Chroicocephalus ridibundus', b: '167', c: 'Par Richard Crossley — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=29316205', d: 'Mouette rieuse'},
		{a: 'Anser anser', b: '168', c: 'Par Diliff — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=1385555', d: 'Oie cendrée'},
		{a: 'Anser albifrons', b: '169', c: 'Public domain, https://commons.wikimedia.org/w/index.php?curid=35147', d: 'Oie rieuse'},
		{a: 'Alopochen aegyptiaca', b: '170', c: 'Par Bernard DUPONT — CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=40724877', d: 'Ouette d\'Égypte'},
		{a: 'Panurus biarmicus', b: '171', c: 'Par Kaeptn chemnitz — CC BY 3.0, https://commons.wikimedia.org/w/index.php?curid=5033332', d: 'Panure à moustaches'},
		{a: 'Perdix perdix', b: '172', c: 'Par Marek Szczepanek — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=13404871', d: 'Perdrix grise'},
		{a: 'Psittacula krameri', b: '173', c: 'Par J.M.Garg — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=2975074', d: 'Perruche à collier'},
		{a: 'Charadrius dubius', b: '174', c: 'Par Pierre Dalous — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=20768943', d: 'Petit Gravelot'},
		{a: 'Dendrocopos major', b: '175', c: 'Par AbdAllah Elgolli — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=49099745', d: 'Pic épeiche'},
		{a: 'Dendrocopos minor', b: '176', c: 'Par Thermos — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=1837011', d: 'Pic épeichette'},
		{a: 'Dendrocopos medius', b: '177', c: 'CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=334121', d: 'Pic mar'},
		{a: 'Dryocopus martius', b: '178', c: 'Par Alastair Rae — CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=29371', d: 'Pic noir'},
		{a: 'Picus viridis', b: '179', c: 'Par Sven Teschke — CC BY-SA 2.0 de, https://commons.wikimedia.org/w/index.php?curid=173915', d: 'Pic vert'},
		{a: 'Lanius collurio', b: '180', c: 'Par Martin Mecnarowski — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=12691146', d: 'Pie-grièche écorcheur'},
		{a: 'Lanius excubitor', b: '181', c: 'Par Marek Szczepanek — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=98250', d: 'Pie-grièche grise'},
		{a: 'Pica pica', b: '182', c: 'Par User:diginatur — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=9717912', d: 'Pie bavarde'},
		{a: 'Columba livia', b: '183', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=794674', d: 'Pigeon biset'},
		{a: 'Columba palumbus', b: '184', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=14677999', d: 'Pigeon ramier'},
		{a: 'Fringilla coelebs', b: '185', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=15264293', d: 'Pinson des arbres'},
		{a: 'Fringilla montifringilla', b: '186', c: 'Par Pierre Dalous — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=25152678', d: 'Pinson du Nord'},
		{a: 'Anthus trivialis', b: '187', c: 'CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=98311', d: 'Pipit des arbres'},
		{a: 'Anthus pratensis', b: '188', c: 'Par Jenny Jones — CC0, https://commons.wikimedia.org/w/index.php?curid=47139448', d: 'Pipit farlouse'},
		{a: 'Anthus spinoletta', b: '189', c: 'Par Marboline — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=727857', d: 'Pipit spioncelle'},
		{a: 'Pluvialis apricaria', b: '190', c: 'Par Sylvain Haye — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=7740632', d: 'Pluvier doré'},
		{a: 'Phylloscopus trochilus', b: '191', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=14979556', d: 'Pouillot fitis'},
		{a: 'Phylloscopus sibilatrix', b: '192', c: 'Par Steve Garvie — CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=11461348', d: 'Pouillot siffleur'},
		{a: 'Phylloscopus collybita', b: '193', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=26494111', d: 'Pouillot véloce'},
		{a: 'Rallus aquaticus', b: '194', c: 'Par Pierre Dalous — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=20972301', d: 'Râle d\'eau'},
		{a: 'Crex crex', b: '195', c: 'Par Johann Friedrich Naumann — Public domain, https://commons.wikimedia.org/w/index.php?curid=252974', d: 'Râle des genêts'},
		{a: 'Regulus regulus', b: '196', c: 'Par Francis C. Franklin — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=38667947', d: 'Roitelet huppé'},
		{a: 'Regulus ignicapilla', b: '197', c: 'Par Regulus_ignicapillus_1.jpg — CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=8935861', d: 'Roitelet triple-bandeau'},
		{a: 'Luscinia megarhynchos', b: '198', c: 'Par Orchi — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=503659', d: 'Rossignol philomèle'},
		{a: 'Erithacus rubecula', b: '199', c: 'Par PierreSelim — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=18454304', d: 'Rougegorge familier'},
		{a: 'Phoenicurus phoenicurus', b: '200', c: 'Par Monique Bogaerts — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=309389', d: 'Rougequeue à front blanc'},
		{a: 'Phoenicurus ochruros', b: '201', c: 'Par Gunther Hasler — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=4763131', d: 'Rougequeue noir'},
		{a: 'Acrocephalus scirpaceus', b: '202', c: 'Par Paolo Bertinetto — CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=3710676', d: 'Rousserolle effarvatte'},
		{a: 'Acrocephalus palustris', b: '203', c: 'CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=88999', d: 'Rousserolle verderolle'},
		{a: 'Anas querquedula', b: '204', c: 'Par Luciano 95 — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=46102907', d: 'Sarcelle d\'été'},
		{a: 'Anas crecca', b: '205', c: 'Par Christophe Eyquem — CC BY 3.0, https://commons.wikimedia.org/w/index.php?curid=129830', d: 'Sarcelle d\'hiver'},
		{a: 'Sitta europaea', b: '206', c: 'Par Smudge 9000 — CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=31588232', d: 'Sittelle torchepot'},
		{a: 'Carduelis flammea', b: '207', c: 'Par Jyrki Salmi — CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=30296090', d: 'Sizerin flammé'},
		{a: 'Platalea leucorodia', b: '208', c: 'Par Pierre Dalous — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=23300565', d: 'Spatule blanche'},
		{a: 'Sterna hirundo', b: '209', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=4111088', d: 'Sterne pierregarin'},
		{a: 'Tadorna tadorna', b: '210', c: 'Par Ludovic Hirlimann — CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=45489844', d: 'Tadorne de Belon'},
		{a: 'Saxicola rubetra', b: '211', c: 'CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=91380', d: 'Tarier des prés'},
		{a: 'Saxicola rubicola', b: '212', c: 'Par J. Malik — https://commons.wikimedia.org/w/index.php?curid=5679147', d: 'Tarier pâtre'},
		{a: 'Carduelis spinus', b: '213', c: 'Par Ghislain38 — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=24255875', d: 'Tarin des aulnes'},
		{a: 'Lyrurus tetrix', b: '214', c: 'Public domain, https://commons.wikimedia.org/w/index.php?curid=209724', d: 'Tétras lyre'},
		{a: 'Arenaria interpres', b: '215', c: 'Par <span class=\"fn value\">\nHans Hillewaert</span> — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=15222118', d: 'Tournepierre à collier'},
		{a: 'Streptopelia turtur', b: '216', c: 'Par David King — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=4871549', d: 'Tourterelle des bois'},
		{a: 'Streptopelia decaocto', b: '217', c: 'Par Camille Gévaudan — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=31407758', d: 'Tourterelle turque'},
		{a: 'Oenanthe oenanthe', b: '218', c: 'Par Philippe Kurlapski — CC BY 1.0, https://commons.wikimedia.org/w/index.php?curid=824357', d: 'Traquet motteux'},
		{a: 'Troglodytes troglodytes', b: '219', c: 'Par Robert Lorch — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=2720579', d: 'Troglodyte mignon'},
		{a: 'Vanellus vanellus', b: '220', c: 'Par Gidzy — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=6447306', d: 'Vanneau huppé'},
		{a: 'Chloris chloris', b: '221', c: 'Par Andreas Trepte — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=6299100', d: 'Verdier d\'Europe'}
	]);
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{i: nodeList, f: nodeListSize, h: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $author$project$StringHelper$replaceTemplate = F2(
	function (toReplace, str) {
		return A3($elm$core$String$replace, '${' + (toReplace.a + '}'), toReplace.b, str);
	});
var $author$project$StringHelper$interpolate = F2(
	function (originalStr, replacementList) {
		return A3($elm$core$List$foldl, $author$project$StringHelper$replaceTemplate, originalStr, replacementList);
	});
var $author$project$Quiz$getImgWildlifeQuiz = F3(
	function (quizData, wlType, options) {
		return $elm$core$Array$fromList(
			A2(
				$elm$core$List$map,
				function (item) {
					return {
						ac: function () {
							if (!wlType) {
								return item.d;
							} else {
								return item.a;
							}
						}(),
						ao: A2(
							$author$project$StringHelper$interpolate,
							'/assets/img/${folder}/resized/${prefix}_${id}.jpg',
							_List_fromArray(
								[
									_Utils_Tuple2('folder', options.a4),
									_Utils_Tuple2('prefix', options.bg),
									_Utils_Tuple2('id', item.b)
								])),
						bl: item.c
					};
				},
				quizData));
	});
var $author$project$BelgianBirds$gameOverMsgs = {a6: 'Merci, tu as rendu les oiseaux heureux avec ce joli score...', bc: 'Les oiseaux restent perplexes face à ton score...', bh: 'Parfait ! Tu fais la fierté du chef des oiseaux avec ce magnifique score...', bj: 'Oh non ! Tu as rendu les oiseaux tristes avec ce mauvais score...'};
var $author$project$BelgianBirds$options = {a4: 'belgian_birds', a5: $author$project$BelgianBirds$gameOverMsgs, bg: 'bb'};
var $author$project$BelgianBirds$binominalBirdsQuiz = {
	aF: $author$project$BelgianBirds$options,
	aM: A3($author$project$Quiz$getImgWildlifeQuiz, $author$project$BelgianBirds$data, 1, $author$project$BelgianBirds$options),
	aU: 'BinominalBirds',
	aW: 'Oiseaux (binominal)'
};
var $author$project$BelgianInsects$data = _List_fromArray(
	[
		{a: 'Apis mellifera', b: '101', c: 'Par Makro Freak — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=2202349', d: 'Abeille domestique'},
		{a: 'Aeshna cyanea', b: '102', c: 'Par Aka — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=204505', d: 'Aeshne bleue'},
		{a: 'Anax imperator', b: '103', c: 'Par Frank Vassen — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=43769436', d: 'Anax empereur'},
		{a: 'Anthocharis cardamines', b: '104', c: 'Par Kjetil Fjellheim — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=64270070', d: 'Aurore'},
		{a: 'Cynthia cardui', b: '105', c: 'Par Didier Descouens — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=41260118', d: 'Belle-Dame'},
		{a: 'Geotrupes stercorarius', b: '106', c: 'Par Kompak~commonswiki — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=211219', d: 'Bousier'},
		{a: 'Carabus auratus', b: '107', c: 'Par Didier Descouens — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=9934173', d: 'Carabe doré'},
		{a: 'Cercopis vulnerata', b: '108', c: 'Par Richard Bartz — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=4101583', d: 'Cercope sanguinolent'},
		{a: 'Cetonia aurata', b: '109', c: 'Par Didier Descouens — CC BY 4.0, https://commons.wikimedia.org/w/index.php?curid=9972580', d: 'Cétoine dorée'},
		{a: 'Cicindela campestris', b: '110', c: 'Par Olei — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=207723', d: 'Cicindèle champêtre'},
		{a: 'Timarcha tenebricosa', b: '111', c: 'Par Fritz Geller-Grimm — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=453339', d: 'Crache-Sang'},
		{a: 'Oedipoda caerulescens', b: '112', c: 'Par Didier Descouens — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=15586266', d: 'Criquet à ailes bleues'},
		{a: 'Coccinella septempunctata', b: '113', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=128757', d: 'Coccinelle à 7 points'},
		{a: 'Melanargia galathea', b: '114', c: 'Par Michael Apel — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=2372702', d: 'Demi-Deuil'},
		{a: 'Dytiscus marginalis', b: '115', c: 'Par Reitter_Dytiscus_u.a..jpg — Public domain, https://commons.wikimedia.org/w/index.php?curid=8686832', d: 'Dytique marginé'},
		{a: 'Iphiclides podalirius', b: '116', c: 'Par Tbc — Public domain, https://commons.wikimedia.org/w/index.php?curid=97235', d: 'Flambé'},
		{a: 'Vespa crabro', b: '117', c: 'Par Didier Descouens — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=10899731', d: 'Frelon'},
		{a: 'Aporia crataegi', b: '118', c: 'Par Michael Apel — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=2192208', d: 'Gazé'},
		{a: 'Gerris lacustris', b: '119', c: 'Par James K. Lindsey — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=1704190', d: 'Gerris'},
		{a: 'Apatura iris', b: '120', c: 'Par Didier Descouens — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=41522658', d: 'Grand mars changeant'},
		{a: 'Tettigonia viridissima', b: '121', c: 'Par User:daniel.ruprecht — CC BY 2.5, https://commons.wikimedia.org/w/index.php?curid=952783', d: 'Grande sauterelle verte'},
		{a: 'Nemobius sylvestris', b: '122', c: 'Par Piet Spaans — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=1600779', d: 'Grillon des bois'},
		{a: 'Melolontha melolontha', b: '123', c: 'Par Didier Descouens — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=15874436', d: 'Hanneton'},
		{a: 'Platetrum depressum', b: '124', c: 'Par Tim Bekaert — Public domain, https://commons.wikimedia.org/w/index.php?curid=210236', d: 'Libellule déprimée'},
		{a: 'Lucanus cervus', b: '125', c: 'Par Didier Descouens — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=25239625', d: 'Lucane cerf-volant'},
		{a: 'Papilio machaon', b: '126', c: 'Par Gerhard Obermayr — CC BY-SA 2.0 de, https://commons.wikimedia.org/w/index.php?curid=9885486', d: 'Machaon'},
		{a: 'Mantis religiosa', b: '127', c: 'Par Alvesgaspar — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=2641596', d: 'Mante religieuse'},
		{a: 'Panorpa communis', b: '128', c: 'Par GabrielBuissart — CC BY 2.5, https://commons.wikimedia.org/w/index.php?curid=2496331', d: 'Mouche-scorpion'},
		{a: 'Nepa cinerea', b: '129', c: 'Par Didier Descouens — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=36490808', d: 'Nèpe cendrée'},
		{a: 'Inachis io', b: '130', c: 'Par Didier Descouens — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=41395587', d: 'Paon du jour'},
		{a: 'Aglais urticae', b: '131', c: 'Par Didier Descouens — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=41320274', d: 'Petite tortue'},
		{a: 'Lepisma saccharina', b: '132', c: 'Par Sebastian Stabinger — CC BY, https://commons.wikimedia.org/w/index.php?curid=189366', d: 'Poisson d\'argent'},
		{a: 'Pyrrhocoris apterus', b: '133', c: 'Par Jpbazard — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=8294560', d: 'Punaise gendarme'},
		{a: 'Graphosoma italicum', b: '134', c: 'Par Fritz Geller-Grimm — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=614317', d: 'Punaise arlequin'},
		{a: 'Sialis lutaria', b: '135', c: 'Par Unknown artist — Attribution, https://commons.wikimedia.org/w/index.php?curid=871677', d: 'Sialis de la vase'},
		{a: 'Urocerus gigas', b: '136', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=268572', d: 'Sirex géant'},
		{a: 'Trichius fasciatus', b: '137', c: 'Par Didier Descouens — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=26904952', d: 'Trichie fasciée'},
		{a: 'Vanessa atalanta', b: '138', c: 'Par Didier Descouens — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=41180640', d: 'Vulcain'},
		{a: 'Ischnura elegans', b: '139', c: 'Par Thomas Bresson — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=6726502', d: 'Agrion'},
		{a: 'Blattella germanica', b: '140', c: 'Par David Monniaux — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=1611434', d: 'Blatte'},
		{a: 'Bombylius major', b: '141', c: 'Par Unknown artist — GPL, https://commons.wikimedia.org/w/index.php?curid=168876', d: 'Bombyle'},
		{a: 'Bombus lucorum', b: '142', c: 'Par Ivar Leidus — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=42305170', d: 'Bourdon'},
		{a: 'Calopteryx virgo', b: '143', c: 'Par Zeynel Cebeci — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=26976515', d: 'Caloptéryx'},
		{a: 'Sitophilus oryzae', b: '144', c: 'Par Olaf Leillinger — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=1550147', d: 'Charançon'},
		{a: 'Nothochrysa fulviceps', b: '145', c: 'Par Siga — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=4571723', d: 'Chrysope'},
		{a: 'Ephemera danica', b: '146', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=243854', d: 'Éphémère'},
		{a: 'Solenopsis invicta', b: '147', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=326305', d: 'Fourmi'},
		{a: 'Vespula germanica', b: '148', c: 'Par Unknown artist — GFDL 1.2, https://commons.wikimedia.org/w/index.php?curid=1931193', d: 'Guêpe'},
		{a: 'Ichneumon suspiciosus', b: '149', c: 'Par AfroBrazilian — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=26536367', d: 'Ichneumon'},
		{a: 'Musca domestica', b: '150', c: 'Par Muhammad Mahdi Karim — GFDL 1.2, https://commons.wikimedia.org/w/index.php?curid=7672794', d: 'Mouche'},
		{a: 'Aedes albopictus', b: '151', c: 'Par James Gathany, CDC — Public domain, https://commons.wikimedia.org/w/index.php?curid=3670049', d: 'Moustique'},
		{a: 'Forficula smyrnensis', b: '152', c: 'Par Assafn — CC BY 2.5, https://commons.wikimedia.org/w/index.php?curid=2108658', d: 'Perce-oreilles'},
		{a: 'Calaphis flava', b: '153', c: 'Par Sanjay ach — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=2233748', d: 'Puceron'},
		{a: 'Scolytus multistriatus', b: '154', c: 'Par Emil Hochdanz — Public domain, https://commons.wikimedia.org/w/index.php?curid=4972896', d: 'Scolyte'},
		{a: 'Proserpinus flavofasciata', b: '155', c: 'Par Didier Descouens — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=46946117', d: 'Sphinx'},
		{a: 'Paederus littoralis', b: '156', c: 'Par Lmbuga — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=10560364', d: 'Staphylin'},
		{a: 'Episyrphus balteatus', b: '157', c: 'Par Aka — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=221925', d: 'Syrphe'},
		{a: 'Chrysops relictus', b: '158', c: 'Par Eric Steinert — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=193492', d: 'Taon'},
		{a: 'Agriotes lineatus', b: '159', c: 'Par Darius Bauzys — Attribution, https://commons.wikimedia.org/w/index.php?curid=4202052', d: 'Taupin'},
		{a: 'Tipula oleracea', b: '160', c: 'Par James K. Lindsey — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=1971398', d: 'Tipule'},
		{a: 'Zygaena trifolii', b: '161', c: 'Par Frank Vassen — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=17018534', d: 'Zygène'},
		{a: 'Perlinella drymo', b: '162', c: 'Travail personnel —  Domaine public, https://commons.wikimedia.org/w/index.php?curid=423626', d: 'Perle'}
	]);
var $author$project$BelgianInsects$gameOverMsgs = {a6: 'Bravo ! Tu as gagné un joli sourire de charançon.', bc: 'Pas mal. Mais ça n\'arrêtera pas la fourmi dans son travail.', bh: 'Parfait ! Tu as compris toute la complexité du monde des insectes.', bj: 'Oh non ! Tu as mis l\'abeille sauvage en colère...'};
var $author$project$BelgianInsects$options = {a4: 'belgian_insects', a5: $author$project$BelgianInsects$gameOverMsgs, bg: 'bi'};
var $author$project$BelgianInsects$binominalInsectsQuiz = {
	aF: $author$project$BelgianInsects$options,
	aM: A3($author$project$Quiz$getImgWildlifeQuiz, $author$project$BelgianInsects$data, 1, $author$project$BelgianInsects$options),
	aU: 'BinominalInsects',
	aW: 'Insectes (binominal)'
};
var $author$project$BelgianMammals$data = _List_fromArray(
	[
		{a: 'Felis sylvestris', b: '219', c: 'Par Lviatour — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=24703445', d: 'Chat sylvestre'},
		{a: 'Lynx lynx', b: '220', c: 'Par mpiet — CC BY-SA 2.0 de, https://commons.wikimedia.org/w/index.php?curid=453440', d: 'Lynx boréal'},
		{a: 'Vulpes vulpes', b: '221', c: 'Par Peter Trimming — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=19027852', d: 'Renard roux'},
		{a: 'Meles meles', b: '222', c: 'Par Peter Trimming — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=17199217', d: 'Blaireau'},
		{a: 'Martes martes', b: '223', c: 'Par Édouard Hue — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=37059724', d: 'Martre'},
		{a: 'Martes foina', b: '224', c: 'Par Steinmarder_01.jpg — CC BY 2.0 de, https://commons.wikimedia.org/w/index.php?curid=15635960', d: 'Fouine'},
		{a: 'Mustela erminea', b: '225', c: 'Par Steve Hillebrand, USFWS — Public domain, https://commons.wikimedia.org/w/index.php?curid=4658019', d: 'Hermine'},
		{a: 'Mustela nivalis', b: '226', c: 'Par Keven Law — CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=4580080', d: 'Belette'},
		{a: 'Mustela putorius', b: '227', c: 'Par Hemmer — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=1619862', d: 'Putois'},
		{a: 'Lutra lutra', b: '228', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=23468', d: 'Loutre'},
		{a: 'Erinaceus europaeus', b: '229', c: 'Par Jörg Hempel — CC BY-SA 2.0 de, https://commons.wikimedia.org/w/index.php?curid=2911084', d: 'Hérisson'},
		{a: 'Talpa europaea', b: '230', c: 'Par Didier Descouens — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=23203253', d: 'Taupe'},
		{a: 'Sus scrofa', b: '231', c: 'Par A.Savin — FAL, https://commons.wikimedia.org/w/index.php?curid=65074239', d: 'Sanglier'},
		{a: 'Cervus elaphus', b: '232', c: 'Par Lviatour — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=16566391', d: 'Cerf européen'},
		{a: 'Dama dama', b: '233', c: 'Par Johann-Nikolaus Andreae — CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=46563187', d: 'Daim'},
		{a: 'Capreolus capreolus', b: '234', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=112551', d: 'Chevreuil'},
		{a: 'Ovis ammon', b: '235', c: 'Par Momotarou2012 — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=26274659', d: 'Mouflon'},
		{a: 'Sciurus vulgaris', b: '236', c: 'Par Ray eye — CC BY-SA 2.0 de, https://commons.wikimedia.org/w/index.php?curid=2192065', d: 'Écureuil roux'},
		{a: 'Glis glis', b: '237', c: 'Par Bertille de Fombelle — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=27438670', d: 'Loir'},
		{a: 'Muscardinus avellanarius', b: '238', c: 'Par Björn Schulz — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=1221362', d: 'Muscardin'},
		{a: 'Eliomys quercinus', b: '239', c: 'Par Jctramasure — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=10402796', d: 'Lérot'},
		{a: 'Castor fiber', b: '240', c: 'Par Per Harald Olsen — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=944464', d: 'Castor d\'Europe'},
		{a: 'Rattus norvegicus', b: '241', c: 'Par Reg Mckenna — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=3875126', d: 'Rat surmulot'},
		{a: 'Rattus rattus', b: '242', c: 'Par Liftarn — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=138395', d: 'Rat noir'},
		{a: 'Mus domesticus', b: '243', c: 'Par <span lang=\"fr\">Inconnu</span> — Public domain, https://commons.wikimedia.org/w/index.php?curid=28335', d: 'Souris grise'},
		{a: 'Micromys minutus', b: '244', c: 'Par Hecke — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=779022', d: 'Rat des moissons'},
		{a: 'Cricetus cricetus', b: '245', c: 'Par Agnieszka Szeląg — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=27796341', d: 'Hamster'},
		{a: 'Clethrionomys glareolus', b: '246', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=269464', d: 'Campagnol roussâtre'},
		{a: 'Ondatra zibethicus', b: '247', c: 'Par David Menke — Public domain, https://commons.wikimedia.org/w/index.php?curid=357863', d: 'Rat musqué'},
		{a: 'Oryctolagus cuniculus', b: '248', c: 'Par JJ Harrison — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=8283824', d: 'Lapin de garenne'},
		{a: 'Eutamias sibiricus', b: '249', c: 'Par AndiW — Public domain, https://commons.wikimedia.org/w/index.php?curid=49109', d: 'Tamia de Sibérie'},
		{a: 'Myocastor coypus', b: '250', c: 'Par Norbert Nagel — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=23360757', d: 'Ragondin'},
		{a: 'Lepus europaeus', b: '251', c: 'Par Jean-Jacques Boujot — CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=37547558', d: 'Lièvre d\'Europe'},
		{a: 'Apodemus sylvaticus', b: '284', c: 'Par BlueBreezeWiki — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=70256219', d: 'Mulot sylvestre'},
		{a: 'Sorex minutus', b: '285', c: 'Par Importé sur Commons par Salix. Sur Flikr par Polandeze — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=5461289', d: 'Musaraigne pygmée'},
		{a: 'Microtus arvalis', b: '286', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=174331', d: 'Campagnol des champs'}
	]);
var $author$project$BelgianMammals$gameOverMsgs = {a6: 'Merci, tu as rendu un renard heureux avec ce joli score...', bc: 'Ton score moyen laisse ce sanglier plutôt indifférent...', bh: 'Parfait ! Le cerf majestueux rend hommage ta sagesse...', bj: 'Oh non ! Tu as fait pleurer le petit renard avec ce mauvais score...'};
var $author$project$BelgianMammals$options = {a4: 'belgian_mammals', a5: $author$project$BelgianMammals$gameOverMsgs, bg: 'bm'};
var $author$project$BelgianMammals$binominalMammalsQuiz = {
	aF: $author$project$BelgianMammals$options,
	aM: A3($author$project$Quiz$getImgWildlifeQuiz, $author$project$BelgianMammals$data, 1, $author$project$BelgianMammals$options),
	aU: 'BinominalMammals',
	aW: 'Mammifères (binominal)'
};
var $author$project$BelgianMushrooms$data = _List_fromArray(
	[
		{a: 'Agaricus campestris', b: '121', c: 'Par Unknown artist — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=1710232', d: 'agarics'},
		{a: 'Amanita citrina', b: '122', c: 'Par Bernypisa — Public domain, https://commons.wikimedia.org/w/index.php?curid=1144171', d: 'amanite citrine'},
		{a: 'Amanita pantherina', b: '123', c: 'Par George Chernilevsky — Public domain, https://commons.wikimedia.org/w/index.php?curid=26556137', d: 'amanite panthère'},
		{a: 'Amanita phalloides', b: '124', c: 'Par Archenzo — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=329999', d: 'amanite phalloïde'},
		{a: 'Amanita rubescens', b: '125', c: 'Par Andreas Kunze — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=15954511', d: 'amanite rougissante'},
		{a: 'Amanita muscaria', b: '126', c: 'Par H. Krisp — CC BY 3.0, https://commons.wikimedia.org/w/index.php?curid=35657774', d: 'amanite tue-mouches'},
		{a: 'Armillaria mellea', b: '127', c: 'Par Stu\'s Images — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=17599821', d: 'armillaires'},
		{a: 'Boletus edulis', b: '128', c: 'Par Wawnelid — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=16591742', d: 'bolets'},
		{a: 'Calocera viscosa', b: '129', c: 'https://commons.wikimedia.org/w/index.php?curid=11324234', d: 'calocère visqueuse'},
		{a: 'Cantharellus cibarius', b: '130', c: 'Par Jeffdelonge — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=1651493', d: 'chanterelle'},
		{a: 'Cantharellus tubaeformis', b: '131', c: 'Par Craterellus_tubaeformis_1345904803_69350246bb_o.jpg — CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=12754510', d: 'chanterelle en tube'},
		{a: 'Chlorociboria aeruginascens', b: '132', c: 'Par Jason Hollinger — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=22382508', d: 'chlorosplénie bleuâtre'},
		{a: 'Clavaria rosea', b: '133', c: 'Par Andrew Parker (mycotrope) — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=15626762', d: 'clavaires'},
		{a: 'Clitocybe odora', b: '134', c: 'Par J.Marqua — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=479746', d: 'clitocybes'},
		{a: 'Clitocybe nebularis', b: '135', c: 'Par Archenzo — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=5266036', d: 'clitocybe nébuleux'},
		{a: 'Clitopilus prunulus', b: '136', c: 'Par Strobilomyces — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=28878605', d: 'clitopile petite prune'},
		{a: 'Gymnopus fusipes', b: '137', c: 'Par User:Strobilomyces — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=192879', d: 'collybies'},
		{a: 'Rhodocollybia butyracea', b: '138', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=486365', d: 'collybie beurrée'},
		{a: 'Coprinellus disseminatus', b: '139', c: 'Par B59210 — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=62816647', d: 'coprins'},
		{a: 'Coprinus comatus', b: '140', c: 'Par User:Nino Barbieri — CC BY 2.5, https://commons.wikimedia.org/w/index.php?curid=1681533', d: 'coprin chevelu'},
		{a: 'Coprinellus micaceus', b: '141', c: 'Par Jean-Jacques MILAN — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=695628', d: 'coprin micacé'},
		{a: 'Coprinus atramentarius', b: '142', c: 'Par James Lindsey — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=3565706', d: 'coprin noir d\'encre'},
		{a: 'Cortinarius purpurascens', b: '143', c: 'Par Irene Andersson (irenea) — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=10940286', d: 'cortinaires'},
		{a: 'Cortinarius orellanus', b: '144', c: 'Par Archenzo — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=333209', d: 'crépidotes'},
		{a: 'Inonotus obliquus', b: '145', c: 'Par Tocekas — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=7579174', d: 'croûtes'},
		{a: 'Entoloma sinuatum', b: '146', c: 'Par Jean-Louis Lascoux — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=1962987', d: 'entolomes'},
		{a: 'Hygrophoropsis aurantiaca', b: '147', c: 'Par W.J.Pilsak — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=167766', d: 'fausse chanterelle'},
		{a: 'Geastrum triplex', b: '148', c: 'Par amadej trnkoczy (amadej) — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=9426369', d: 'géastres'},
		{a: 'Gomphidius roseus', b: '149', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=167761', d: 'gomphides'},
		{a: 'Gymnopilus penetrans', b: '150', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=217693', d: 'gymnopiles'},
		{a: 'Hebeloma crustuliniforme', b: '151', c: 'Par User:Strobilomyces — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=1318132', d: 'hébélomes'},
		{a: 'Helvella acetabulum', b: '152', c: 'Par Gerhard Koller (Gerhard) — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=11123183', d: 'helvelles'},
		{a: 'Helvella crispa', b: '153', c: 'Par Nathan Wilson — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=6640931', d: 'helvelle crépue'},
		{a: 'Hygrocybe coccinea', b: '154', c: 'Par Jplm — CC BY 2.5, https://commons.wikimedia.org/w/index.php?curid=1367308', d: 'hygrocybes'},
		{a: 'Hygrophorus agathosmus', b: '155', c: 'Par Ryane Snow (snowman) — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=9060612', d: 'hygrophores'},
		{a: 'Hypholoma capnoides', b: '156', c: 'Par Madjack74 — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=2751716', d: 'hypholome capnoïde'},
		{a: 'Hypholoma sublateritium', b: '157', c: 'Par Archenzo — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=2997388', d: 'hypholome couleur de brique'},
		{a: 'Hypholoma fasciculare', b: '158', c: 'Par Saharadesertfox — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=310731', d: 'hypholome en touffe'},
		{a: 'Inocybe rimosa', b: '159', c: 'Par Eric Steinert — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=353499', d: 'inocybes'},
		{a: 'Laccaria amethystina', b: '160', c: 'Par H. Krisp — CC BY 3.0, https://commons.wikimedia.org/w/index.php?curid=16856622', d: 'laccaire améthyste'},
		{a: 'Laccaria laccata', b: '161', c: 'Par Luridiformis — CC BY 3.0, https://commons.wikimedia.org/w/index.php?curid=16215385', d: 'laccaire laqué'},
		{a: 'Lactarius vellereus', b: '162', c: 'Par Jean-Pol GRANDMONT — CC BY 3.0, https://commons.wikimedia.org/w/index.php?curid=3211293', d: 'lactaires'},
		{a: 'Macrolepiota procera', b: '163', c: 'Par Chrumps — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=35593334', d: 'lépiotes'},
		{a: 'Lycoperdon pyriforme', b: '164', c: 'Par Teuch67 — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=47164355', d: 'lycoperdons'},
		{a: 'Lycoperdon perlatum', b: '165', c: 'Par H. Krisp — CC BY 3.0, https://commons.wikimedia.org/w/index.php?curid=16830686', d: 'lycoperdon perlé'},
		{a: 'Marasmius oreades', b: '166', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=875758', d: 'marasmes'},
		{a: 'Morchella esculenta', b: '167', c: 'Par J.Marqua — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=479828', d: 'morilles'},
		{a: 'Mycena aurantiomarginata', b: '168', c: 'Par Alan Rockefeller — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=23496108', d: 'mycènes'},
		{a: 'Auricularia auricula-judae', b: '169', c: 'Par Jean-Pol GRANDMONT — CC BY 2.5, undefined', d: 'oreille-de-Judas'},
		{a: 'Panaeolus cyanescens', b: '170', c: 'Par Prankster239 — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=2849189', d: 'panéoles'},
		{a: 'Paxillus involutus', b: '171', c: 'Par Strobilomyces — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=465732', d: 'paxille enroulé'},
		{a: 'Peziza repanda', b: '172', c: 'Par Rosser1954 — Public domain, https://commons.wikimedia.org/w/index.php?curid=6681334', d: 'pezizes'},
		{a: 'Sarcoscypha coccinea', b: '173', c: 'Par Ryane Snow (snowman) — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=9040405', d: 'pezize écarlate'},
		{a: 'Aleuria aurantia', b: '174', c: 'Par 2011-11-15_Aleuria_aurantia_6364528013_1a6a132e3e_o.jpg — CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=20143265', d: 'pezize orangée'},
		{a: 'Kuehneromyces mutabilis', b: '175', c: 'Par James Lindsey — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=3566301', d: 'pholiote changeante'},
		{a: 'Pholiota squarrosa', b: '176', c: 'Par Jean-Pol GRANDMONT — CC BY 3.0, https://commons.wikimedia.org/w/index.php?curid=3402820', d: 'pholiote écailleuse'},
		{a: 'Lepista nuda', b: '177', c: 'Par Jörg Hempel — CC BY-SA 3.0 de, https://commons.wikimedia.org/w/index.php?curid=35937774', d: 'pied bleu'},
		{a: 'Lepista sordida', b: '178', c: 'Par Berylverte — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=17522578', d: 'lépiste sordide'},
		{a: 'Hydnum repandum', b: '179', c: 'Par D J Kelly — Public domain, https://commons.wikimedia.org/w/index.php?curid=1328791', d: 'pied de mouton'},
		{a: 'Pleurotus dryinus', b: '180', c: 'Par Jean-Pol GRANDMONT — CC BY 3.0, https://commons.wikimedia.org/w/index.php?curid=3284465', d: 'pleurotes'},
		{a: 'Pleurotus ostreatus', b: '181', c: 'Par voir ci-dessous / see below — CC BY 3.0, https://commons.wikimedia.org/w/index.php?curid=3330811', d: 'pleurote en huître'},
		{a: 'Pluteus cervinus', b: '182', c: 'Par Madjack74 — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=2875154', d: 'plutées'},
		{a: 'Polyporus alveolaris', b: '183', c: 'Par Andreas Kunze — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=14907727', d: 'polypores'},
		{a: 'Piptoporus betulinus', b: '184', c: 'Par Jean-Pol GRANDMONT — CC BY 3.0, https://commons.wikimedia.org/w/index.php?curid=3254180', d: 'polypore du bouleau'},
		{a: 'Psathyrella bipellis', b: '185', c: 'Par Boleslaw Kuznik (Bolek) — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=12748622', d: 'psathyrelles'},
		{a: 'Russula emetica', b: '186', c: 'Par MichalPL — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=63737437', d: 'russules'},
		{a: 'Phallus impudicus', b: '187', c: 'Par Dohduhdah — Public domain, https://commons.wikimedia.org/w/index.php?curid=4863462', d: 'satyre puant'},
		{a: 'Scleroderma verrucosum', b: '188', c: 'Par Pau Cabot — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=1735413', d: 'sclérodermes'},
		{a: 'Scleroderma citrinum', b: '189', c: 'Par <div class=\"fn value\">\nHans Hillewaert</div> — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=2668668', d: 'scléroderme commun'},
		{a: 'Stereum hirsutum', b: '190', c: 'Par Andreas Kunze — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=13259539', d: 'stérée hirsute'},
		{a: 'Stropharia pseudocyanea', b: '191', c: 'Par Strobilomyces — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=17308797', d: 'strophaires'},
		{a: 'Stropharia aeruginosa', b: '192', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=777801', d: 'strophaire vert-de-gris'},
		{a: 'Tremella aurantia', b: '193', c: 'Par Tremella_mesenterica_040201A.jpg — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=12841592', d: 'trémelles'},
		{a: 'Tremella mesenterica', b: '194', c: 'Par Jean-Pol GRANDMONT — CC BY 3.0, https://commons.wikimedia.org/w/index.php?curid=3220464', d: 'trémelle mésentérique'},
		{a: 'Tricholoma myomyces', b: '195', c: 'Par User:Strobilomyces — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=1348945', d: 'tricholomes'},
		{a: 'Tricholomopsis rutilans', b: '196', c: 'Par Alberto Vázquez — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=1393518', d: 'tricholome rutilant'},
		{a: 'Craterellus cornucopioides', b: '197', c: 'Par Jean-Pol GRANDMONT — CC BY 3.0, https://commons.wikimedia.org/w/index.php?curid=29154362', d: 'trompette de la mort'},
		{a: 'Tuber melanosporum', b: '198', c: 'Par moi-même — Public domain, https://commons.wikimedia.org/w/index.php?curid=1475134', d: 'truffes'},
		{a: 'Volvariella bombycina', b: '199', c: 'Par Hagen Graebner — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=10762346', d: 'volvaires'},
		{a: 'Xylaria hypoxylon', b: '200', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=121630', d: 'xylaire du bois'},
		{a: 'Xylaria polymorpha', b: '201', c: 'Par Unknown artist — Attribution, https://commons.wikimedia.org/w/index.php?curid=1563550', d: 'xylaire polymorphe'},
		{a: 'Cystoderma spp.', b: '202', c: 'Par James Lindsey at Ecology of Commanster — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=3565900', d: 'cystodermes'},
		{a: 'Melanoleuca spp.', b: '203', c: 'Par Strobilomyces — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=2164642', d: 'mélanoleucas'}
	]);
var $author$project$BelgianMushrooms$gameOverMsgs = {a6: 'Bravo. Tu as bien mérité ce bouquet de champignons variés.', bc: 'Pas mal. Mais il te reste plusieurs étapes avant de savoir cuisiner une bonne omelette.', bh: 'Parfait ! Tu as ta place dans le monde délicat des champignons.', bj: 'Oh non ! Tu es un danger public, il va falloir réviser !'};
var $author$project$BelgianMushrooms$options = {a4: 'belgian_mushrooms', a5: $author$project$BelgianMushrooms$gameOverMsgs, bg: 'bmu'};
var $author$project$BelgianMushrooms$binominalMushroomsQuiz = {
	aF: $author$project$BelgianMushrooms$options,
	aM: A3($author$project$Quiz$getImgWildlifeQuiz, $author$project$BelgianMushrooms$data, 1, $author$project$BelgianMushrooms$options),
	aU: 'BinominalMushrooms',
	aW: 'Champignons (binominal)'
};
var $author$project$BelgianPlants$data = _List_fromArray(
	[
		{a: 'Achillea millefolium', b: '101', c: 'Par Franz Eugen Köhler, <i>Köhler\'s Medizinal-Pflanzen</i> — Public domain, https://commons.wikimedia.org/w/index.php?curid=255484', d: 'achillée millefeuille'},
		{a: 'Agrimonia eupatoria', b: '102', c: 'Par User:Kilom691 — Public domain, https://commons.wikimedia.org/w/index.php?curid=2077324', d: 'aigremoine eupatoire'},
		{a: 'Allium ursinum', b: '103', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=2700416', d: 'ail des ours'},
		{a: 'Alchemilla xanthochlora', b: '104', c: 'Par H. Zell — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=8969769', d: 'alchémille vert jaunâtre'},
		{a: 'Alliaria officinalis', b: '105', c: 'Par Jeffdelonge — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=1635878', d: 'alliaire officinale'},
		{a: 'Aquilegia vulgaris', b: '106', c: 'Par Jeffdelonge — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=2041344', d: 'ancolie commune'},
		{a: 'Anemone nemorosa', b: '107', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=452712', d: 'anémone sylvie'},
		{a: 'Angelica sylvestris', b: '108', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=8842', d: 'angélique sauvage'},
		{a: 'Anthriscus sylvestris', b: '109', c: 'Par Franz Eugen Köhler, <i>Köhler\'s Medizinal-Pflanzen</i>; Max Vogtherr; M. Gurke; — Public domain, https://commons.wikimedia.org/w/index.php?curid=255499', d: 'anthrisque'},
		{a: 'Artemisia vulgaris', b: '110', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=7569', d: 'armoise commune'},
		{a: 'Galium odoratum', b: '111', c: 'Par Jeffdelonge — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=1669679', d: 'aspérule odorante'},
		{a: 'Impatiens glandulifera', b: '112', c: 'Par Jeantosti — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=4890454', d: 'balsamine de l\'Himalaya'},
		{a: 'Impatiens noli-tangere', b: '113', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=17920644', d: 'balsamine des bois'},
		{a: 'Arctium lappa', b: '114', c: 'Par Christian Fischer — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=4470395', d: 'bardane (grande)'},
		{a: 'Geum urbanum', b: '115', c: 'Par Jacob Sturm — Public domain, https://commons.wikimedia.org/w/index.php?curid=731962', d: 'benoîte commune'},
		{a: 'Heracleum sphondylium', b: '116', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=8850', d: 'berce commune'},
		{a: 'Heracleum mantegazzianum', b: '117', c: 'Par Meneerke bloem — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=33440796', d: 'berce du Caucase'},
		{a: 'Centaurea cyanus', b: '118', c: 'Par Carl Axel Magnus Lindman — Public domain, https://commons.wikimedia.org/w/index.php?curid=936580', d: 'bleuet des champs'},
		{a: 'Verbascum thapsus', b: '119', c: 'Par Jan Kops — Public domain, https://commons.wikimedia.org/w/index.php?curid=9021420', d: 'bouillon blanc'},
		{a: 'Capsella bursa-pastoris', b: '120', c: 'Par Carl Axel Magnus Lindman — Public domain, https://commons.wikimedia.org/w/index.php?curid=200875', d: 'bourse-à-pasteur'},
		{a: 'Prunella vulgaris', b: '121', c: 'Par Ivar Leidus — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=27062399', d: 'brunelle commune'},
		{a: 'Calluna vulgaris', b: '122', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=9572', d: 'bruyère commune'},
		{a: 'Buddleja davidii', b: '123', c: 'Par Jeffdelonge — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=1642094', d: 'buddléa'},
		{a: 'Ajuga reptans', b: '124', c: 'Par Patrice78500 — Public domain, https://commons.wikimedia.org/w/index.php?curid=7058235', d: 'bugle rampante'},
		{a: 'Dipsacus fullonum', b: '125', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=8885', d: 'cabaret des oiseaux'},
		{a: 'Galium mollugo', b: '126', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=7356', d: 'caille-lait blanc'},
		{a: 'Campanula rotundifolia', b: '127', c: 'Par Hans Hillewaert — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=4750293', d: 'campanule à feuilles rondes'},
		{a: 'Cardamine pratensis', b: '128', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=8626', d: 'cardamine des prés'},
		{a: 'Daucus carota', b: '129', c: 'Par Alvesgaspar — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=4077304', d: 'carotte sauvage'},
		{a: 'Chelidonium majus', b: '130', c: 'Par Bff — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=15509008', d: 'chélidoine (grande)'},
		{a: 'Cichorium intybus', b: '131', c: 'Par Carl Axel Magnus Lindman — Public domain, https://commons.wikimedia.org/w/index.php?curid=200885', d: 'chicorée sauvage'},
		{a: 'Circaea lutetiana', b: '132', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=256432', d: 'circée de Paris'},
		{a: 'Cirsium arvense', b: '133', c: 'Par Richard Bartz — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=2619189', d: 'cirse des champs'},
		{a: 'Colchicum autumnale', b: '134', c: 'Par Franz Eugen Köhler, <i>Köhler\'s Medizinal-Pflanzen</i> — Public domain, https://commons.wikimedia.org/w/index.php?curid=255312', d: 'colchique '},
		{a: 'Silene latifolia', b: '135', c: 'Par Johann Georg Sturm (Painter: Jacob Sturm) — Public domain, https://commons.wikimedia.org/w/index.php?curid=761411', d: 'compagnon blanc'},
		{a: 'Silene dioica', b: '136', c: 'Par Jacob Sturm — Public domain, https://commons.wikimedia.org/w/index.php?curid=761444', d: 'compagnon rouge'},
		{a: 'Symphytum officinale', b: '137', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=8714', d: 'consoude (grande)'},
		{a: 'Papaver rhoeas', b: '138', c: 'Par Franz Eugen Köhler, <i>Köhler\'s Medizinal-Pflanzen</i> — Public domain, https://commons.wikimedia.org/w/index.php?curid=255420', d: 'coquelicot (grand)'},
		{a: 'Cymbalaria muralis', b: '139', c: 'Par Alois Staudacher — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=32533481', d: 'cymbalaire'},
		{a: 'Digitalis lutea', b: '140', c: 'Par Bernd Haynold — CC BY 2.5, https://commons.wikimedia.org/w/index.php?curid=220606', d: 'digitale jaune'},
		{a: 'Digitalis purpurea', b: '141', c: 'Par Walther Otto Müller — Public domain, https://commons.wikimedia.org/w/index.php?curid=1815791', d: 'digitale pourpre'},
		{a: 'Hieracium pilosella', b: '142', c: 'Par Prazak — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=867298', d: 'épervière piloselle'},
		{a: 'Stachys sylvatica', b: '143', c: 'Par Ivar Leidus — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=26893216', d: 'épiaire des bois'},
		{a: 'Epilobium angustifolium', b: '144', c: 'Par Wknight94 — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=8769905', d: 'épilobe en épi'},
		{a: 'Epilobium hirsutum', b: '145', c: 'Par Jeffdelonge — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=2263428', d: 'épilobe hirsute'},
		{a: 'Eupatorium cannabinum', b: '146', c: 'Par Carl Axel Magnus Lindman — Public domain, https://commons.wikimedia.org/w/index.php?curid=385679', d: 'eupatoire chanvrine'},
		{a: 'Euphorbia amygdaloides', b: '147', c: 'Par Rasbak — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=134662', d: 'euphorbe des bois'},
		{a: 'Euphorbia helioscopia', b: '148', c: 'Par Júlio Reis — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=804337', d: 'euphorbe réveil-matin'},
		{a: 'Ranunculus ficaria', b: '149', c: 'Par Carl Axel Magnus Lindman — Public domain, https://commons.wikimedia.org/w/index.php?curid=185660', d: 'ficaire'},
		{a: 'Fragaria vesca', b: '150', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=9172', d: 'fraisier des bois'},
		{a: 'Rubus idaeus', b: '151', c: 'Par Franz Eugen Köhler, <i>Köhler\'s Medizinal-Pflanzen</i> — Public domain, https://commons.wikimedia.org/w/index.php?curid=255451', d: 'framboisier'},
		{a: 'Cruciata laevipes', b: '152', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=132153', d: 'gaillet croisette'},
		{a: 'Galium aparine', b: '153', c: 'Par Prof. Dr. Thomé — Public domain, https://commons.wikimedia.org/w/index.php?curid=8716633', d: 'gaillet gratteron'},
		{a: 'Galeopsis tetrahit', b: '154', c: 'Par Ivar Leidus — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=27544332', d: 'galéopsis tétrahit'},
		{a: 'Cytisus scoparius', b: '155', c: 'Par Franz Eugen Köhler, <i>Köhler\'s Medizinal-Pflanzen</i> — Public domain, https://commons.wikimedia.org/w/index.php?curid=255542', d: 'genêt à balais'},
		{a: 'Teucrium scorodonia', b: '156', c: 'Par Aroche — CC BY 2.5, https://commons.wikimedia.org/w/index.php?curid=1089243', d: 'germandrée scorodoine'},
		{a: 'Lathyrus pratensis', b: '157', c: 'Par Jacob Sturm — Public domain, https://commons.wikimedia.org/w/index.php?curid=744823', d: 'gesse des prés'},
		{a: 'Arum maculatum', b: '158', c: 'Par Prof. Dr. Otto Wilhelm Thomé — Public domain, https://commons.wikimedia.org/w/index.php?curid=4072077', d: 'gouet tacheté'},
		{a: 'Vinca major', b: '159', c: 'Par JJ Harrison — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=7597111', d: 'grande pervenche'},
		{a: 'Galium aparine', b: '160', c: 'Par Prof. Dr. Thomé — Public domain, https://commons.wikimedia.org/w/index.php?curid=8716633', d: 'gratteron'},
		{a: 'Viscum album', b: '161', c: 'Par Walther Otto Müller — Public domain, https://commons.wikimedia.org/w/index.php?curid=255641', d: 'gui'},
		{a: 'Geranium robertianum', b: '162', c: 'Par Ivar Leidus — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=26893210', d: 'herbe à Robert'},
		{a: 'Humulus lupulus', b: '163', c: 'Par Franz Eugen Köhler, <i>Köhler\'s Medizinal-Pflanzen</i> — Public domain, https://commons.wikimedia.org/w/index.php?curid=255347', d: 'houblon'},
		{a: 'Iris pseudacorus', b: '164', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=9103', d: 'iris jaune'},
		{a: 'Hyacinthoides non-scripta', b: '165', c: 'Par Jacob Sturm — Public domain, https://commons.wikimedia.org/w/index.php?curid=736027', d: 'jacinthe des bois'},
		{a: 'Narcissus pseudonarcissus', b: '166', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=114664', d: 'jonquille'},
		{a: 'Sonchus asper', b: '167', c: 'Par Epipactis — CC0, https://commons.wikimedia.org/w/index.php?curid=15558153', d: 'laiteron rude'},
		{a: 'Lamium album', b: '168', c: 'Par Jacob Sturm — Public domain, https://commons.wikimedia.org/w/index.php?curid=737767', d: 'lamier blanc'},
		{a: 'Lamium galeobdolon', b: '169', c: 'Par Jacob Sturm — Public domain, https://commons.wikimedia.org/w/index.php?curid=737768', d: 'lamier jaune'},
		{a: 'Lamium purpureum', b: '170', c: 'Par Unknown artist — CC BY 2.5, https://commons.wikimedia.org/w/index.php?curid=1862472', d: 'lamier pourpre'},
		{a: 'Hedera helix', b: '171', c: 'Par kenraiz — Public domain, https://commons.wikimedia.org/w/index.php?curid=5197146', d: 'lierre (grimpant)'},
		{a: 'Glechoma hederacea', b: '172', c: 'Par Carl Axel Magnus Lindman — Public domain, https://commons.wikimedia.org/w/index.php?curid=5966432', d: 'lierre terrestre'},
		{a: 'Linaria vulgaris', b: '173', c: 'https://commons.wikimedia.org/w/index.php?curid=2085760', d: 'linaire commune'},
		{a: 'Convolvulus arvensis', b: '174', c: 'Par Unknown artist — Public domain, undefined', d: 'liseron des champs'},
		{a: 'Calystegia sepium', b: '175', c: 'Par Ivar Leidus — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=27062397', d: 'liseron des haies'},
		{a: 'Lotus corniculatus', b: '176', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=452716', d: 'lotier corniculé'},
		{a: 'Medicago sativa', b: '177', c: 'Par Prof. Dr. Otto Wilhelm Thomé — Public domain, https://commons.wikimedia.org/w/index.php?curid=4068959', d: 'luzerne cultivée'},
		{a: 'Medicago lupulina', b: '178', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=145010', d: 'luzerne lupuline'},
		{a: 'Lychnis flos-cuculi', b: '179', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=8517', d: 'lychnis fleur de coucou'},
		{a: 'Lysimachia nummularia', b: '180', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=17920639', d: 'lysimaque nummulaire'},
		{a: 'Leucanthemum vulgare', b: '181', c: 'Par Magnus Manske — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=2207618', d: 'marguerite (grande)'},
		{a: 'Typha latifolia', b: '182', c: 'Par Jeffdelonge — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=1609620', d: 'massette à larges feuilles'},
		{a: 'Matricaria discoidea', b: '183', c: 'Par Ivar Leidus — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=27558952', d: 'matricaire discoïde'},
		{a: 'Melampyrum pratense', b: '184', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=8771', d: 'mélampyre des prés'},
		{a: 'Melilotus officinalis', b: '185', c: 'Par Otto Wilhelm Thomé — Public domain, https://commons.wikimedia.org/w/index.php?curid=1412291', d: 'mélilot officinal'},
		{a: 'Mercurialis perennis', b: '186', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=9332', d: 'mercuriale vivace'},
		{a: 'Hypericum perforatum', b: '187', c: 'Par Jeffdelonge — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=1669636', d: 'millepertuis perforé'},
		{a: 'Anagallis arvensis', b: '188', c: 'Par O. Pichard — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=6235614', d: 'mouron rouge'},
		{a: 'Convallaria majalis', b: '189', c: 'Par Franz Eugen Köhler, <i>Köhler\'s Medizinal-Pflanzen</i> — Public domain, https://commons.wikimedia.org/w/index.php?curid=255313', d: 'muguet'},
		{a: 'Vaccinium myrtillus', b: '190', c: 'Par Otto Wilhelm Thomé — Public domain, https://commons.wikimedia.org/w/index.php?curid=9578', d: 'myrtille'},
		{a: 'Nymphaea alba', b: '191', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=458959', d: 'nénuphar blanc'},
		{a: 'Nuphar lutea', b: '192', c: 'Par Hans Hillewaert — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=6813532', d: 'nénuphar jaune'},
		{a: 'Oenothera biennis', b: '193', c: 'Par User:Kilom691 — Public domain, https://commons.wikimedia.org/w/index.php?curid=2081914', d: 'onagre bisannuelle'},
		{a: 'Sedum acre', b: '194', c: 'Par Wilson44691 — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=15900831', d: 'orpin âcre'},
		{a: 'Sedum album', b: '195', c: 'Par Bouba — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=2535890', d: 'orpin blanc'},
		{a: 'Urtica dioica', b: '196', c: 'Par User:Kilom691 — Public domain, https://commons.wikimedia.org/w/index.php?curid=2120727', d: 'ortie dioïque'},
		{a: 'Rumex acetosa', b: '197', c: 'Par Carl Axel Magnus Lindman — Public domain, https://commons.wikimedia.org/w/index.php?curid=5979117', d: 'oseille sauvage'},
		{a: 'Oxalis acetosella', b: '198', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=455542', d: 'oxalis petite oseille'},
		{a: 'Bellis perennis', b: '199', c: 'Par Jacob Sturm — Public domain, https://commons.wikimedia.org/w/index.php?curid=720792', d: 'pâquerette '},
		{a: 'Paris quadrifolia', b: '200', c: 'Par Frédéric — Public domain, https://commons.wikimedia.org/w/index.php?curid=3685596', d: 'parisette'},
		{a: 'Tussilago farfara', b: '201', c: 'Par Franz Eugen Köhler, <i>Köhler\'s Medizinal-Pflanzen</i> — Public domain, https://commons.wikimedia.org/w/index.php?curid=255476', d: 'pas d\'âne'},
		{a: 'Viola arvensis', b: '202', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=17920581', d: 'pensée des champs'},
		{a: 'Viola tricolor', b: '203', c: 'Par Walther Otto Müller — Public domain, https://commons.wikimedia.org/w/index.php?curid=255640', d: 'pensée sauvage'},
		{a: 'Galanthus nivalis', b: '204', c: 'Par Tocekas — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=3728295', d: 'perce-neige'},
		{a: 'Vinca minor', b: '205', c: 'Par Otto Wilhelm Thomé — Public domain, https://commons.wikimedia.org/w/index.php?curid=8606', d: 'petite pervenche'},
		{a: 'Phragmites australis', b: '206', c: 'Par Le.Loup.Gris — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=16682936', d: 'phragmite'},
		{a: 'Plantago major', b: '207', c: 'Par Original book source: Prof. Dr. Otto Wilhelm Thomé <i>Flora von Deutschland, Österreich und der Schweiz</i> 1885, Gera, Germany — Public domain, https://commons.wikimedia.org/w/index.php?curid=2818720', d: 'plantain à larges feuilles'},
		{a: 'Plantago lanceolata', b: '208', c: 'Par Johann Georg Sturm (Painter: Jacob Sturm) — Public domain, https://commons.wikimedia.org/w/index.php?curid=744926', d: 'plantain lancéolé'},
		{a: 'Caltha palustris', b: '209', c: 'Par Jeffdelonge — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=2266258', d: 'populage'},
		{a: 'Potentilla anserina', b: '210', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=9184', d: 'potentille ansérine'},
		{a: 'Potentilla reptans', b: '211', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=9152', d: 'potentille rampante'},
		{a: 'Equisetum arvense', b: '212', c: 'Par Walter Siegmund — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=8726511', d: 'prêle des champs'},
		{a: 'Primula elatior', b: '213', c: 'Par Jeffdelonge — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=1665745', d: 'primevère élevée'},
		{a: 'Primula veris', b: '214', c: 'Par Prof. Dr. Otto Wilhelm Thomé — Public domain, https://commons.wikimedia.org/w/index.php?curid=4062847', d: 'primevère officinale'},
		{a: 'Pulmonaria officinalis', b: '215', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=8715', d: 'pulmonaire officinale'},
		{a: 'Filipendula ulmaria', b: '216', c: 'Par Ivar Leidus — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=27165314', d: 'reine-des-prés'},
		{a: 'Ranunculus acris', b: '217', c: 'Par Original book source:\n<p>Prof. Dr. Otto Wilhelm Thomé <i>Flora von Deutschland, Österreich und der Schweiz</i>\n</p>\n1885, Gera, Germany — Public domain, https://commons.wikimedia.org/w/index.php?curid=3477298', d: 'renoncule âcre'},
		{a: 'Ranunculus repens', b: '218', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=449763', d: 'renoncule rampante'},
		{a: 'Fallopia japonica', b: '219', c: 'Par Pixeltoo — Public domain, https://commons.wikimedia.org/w/index.php?curid=8162716', d: 'renouée du Japon'},
		{a: 'Persicaria maculosa', b: '220', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=249988', d: 'renouée persicaire'},
		{a: 'Rosa canina', b: '221', c: 'Par Amédée Masclef — Public domain, https://commons.wikimedia.org/w/index.php?curid=5771133', d: 'rosier des chiens'},
		{a: 'Drosera rotundifolia', b: '222', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=108185', d: 'rossolis à feuilles rondes'},
		{a: 'Lythrum salicaria', b: '223', c: 'Par H. Zell — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=10643490', d: 'salicaire commune'},
		{a: 'Tragopogon pratensis', b: '224', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=175049', d: 'salsifis des prés'},
		{a: 'Polygonatum multiflorum', b: '225', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=883011', d: 'sceau-de-Salomon multiflore'},
		{a: 'Scrophularia nodosa', b: '226', c: 'Par Original book source: Prof. Dr. Otto Wilhelm Thomé <i>Flora von Deutschland, Österreich und der Schweiz</i> 1885, Gera, Germany — Public domain, https://commons.wikimedia.org/w/index.php?curid=7047122', d: 'scrofulaire noueuse'},
		{a: 'Senecio vulgaris', b: '227', c: 'Par H. Zell — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=11097718', d: 'séneçon commun'},
		{a: 'Senecio jacobaea', b: '228', c: 'Par Christian Fischer — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=2471811', d: 'séneçon jacobée'},
		{a: 'Stellaria holostea', b: '229', c: 'Par Nova — CC BY 2.5, https://commons.wikimedia.org/w/index.php?curid=523205', d: 'stellaire holostée'},
		{a: 'Stellaria media', b: '230', c: 'Par Carl Axel Magnus Lindman — Public domain, https://commons.wikimedia.org/w/index.php?curid=936668', d: 'stellaire intermédiaire'},
		{a: 'Succisa pratensis', b: '231', c: 'Par Ivar Leidus — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=61619621', d: 'succise des prés'},
		{a: 'Thlaspi arvense', b: '232', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=505898', d: 'tabouret des champs'},
		{a: 'Tanacetum vulgare', b: '233', c: 'Par Ivar Leidus — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=27509982', d: 'tanaisie commune'},
		{a: 'Trifolium pratense', b: '234', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=449768', d: 'trèfle des prés'},
		{a: 'Trifolium repens', b: '235', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=9274', d: 'trèfle rampant'},
		{a: 'Valeriana repens', b: '236', c: 'Par Franz Eugen Köhler, <i>Köhler\'s Medizinal-Pflanzen</i> — Public domain, https://commons.wikimedia.org/w/index.php?curid=255477', d: 'valériane officinale'},
		{a: 'Veronica persica', b: '237', c: 'Par Johann Georg Sturm (Painter: Jacob Sturm) — Public domain, https://commons.wikimedia.org/w/index.php?curid=780041', d: 'véronique de Perse'},
		{a: 'Veronica chamaedrys', b: '238', c: 'Par Roby — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=20455242', d: 'véronique petit-chêne'},
		{a: 'Vicia cracca', b: '239', c: 'Par Johann Georg Sturm (Painter: Jacob Sturm) — Public domain, https://commons.wikimedia.org/w/index.php?curid=787489', d: 'vesce à épis'},
		{a: 'Vicia sepium', b: '240', c: 'Par Jeffdelonge — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=2232832', d: 'vesce des haies'},
		{a: 'Echium vulgare', b: '241', c: 'Par Carl Axel Magnus Lindman — Public domain, https://commons.wikimedia.org/w/index.php?curid=936454', d: 'vipérine commune'},
		{a: 'Rubus fruticosus', b: '242', c: 'Par Jan Kops — Public domain, https://commons.wikimedia.org/w/index.php?curid=18924127', d: 'Ronce commune'},
		{a: 'Salicornia europaea', b: '243', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=9081', d: 'Salicorne d\'Europe'},
		{a: 'Viola odorata', b: '244', c: 'Par Carl Axel Magnus Lindman — Public domain, https://commons.wikimedia.org/w/index.php?curid=723042', d: 'Violette odorante'},
		{a: 'Viola riviniana', b: '245', c: 'Par Ulrika — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=2058106', d: 'Violette de Rivinus'},
		{a: 'Taraxacum', b: '246', c: 'Par Walther Otto Müller — Public domain, https://commons.wikimedia.org/w/index.php?curid=255466', d: 'Pissenlit'}
	]);
var $author$project$BelgianPlants$gameOverMsgs = {a6: 'Bravo ! Tu es aussi belle/beau que ces fleurs, mais encore un peu sauvage...', bc: 'Pas mal. Mais ta maîtrise des plantes est comme ces bourgeons: en devenir...', bh: 'Parfait ! La complexité des fleurs n\'a plus aucun secret pour toi...', bj: 'Oh non ! Tu as fané toutes les fleurs avec ce mauvais score...'};
var $author$project$BelgianPlants$options = {a4: 'belgian_plants', a5: $author$project$BelgianPlants$gameOverMsgs, bg: 'bp'};
var $author$project$BelgianPlants$binominalPlantsQuiz = {
	aF: $author$project$BelgianPlants$options,
	aM: A3($author$project$Quiz$getImgWildlifeQuiz, $author$project$BelgianPlants$data, 1, $author$project$BelgianPlants$options),
	aU: 'BinominalPlants',
	aW: 'Plantes (binominal)'
};
var $author$project$BelgianReptiles$data = _List_fromArray(
	[
		{a: 'Alytes obstetricans', b: '101', c: 'Par Bernard DUPONT — CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=73305913', d: 'alyte accoucheur'},
		{a: 'Bufo calamita', b: '102', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=109958', d: 'crapaud calamite'},
		{a: 'Bufo bufo', b: '103', c: 'Par Iric — CC BY-SA 2.5, https://commons.wikimedia.org/w/index.php?curid=852982', d: 'crapaud commun'},
		{a: 'Rana temporaria', b: '104', c: 'Par H. Krisp — CC BY 3.0, https://commons.wikimedia.org/w/index.php?curid=16316567', d: 'grenouille rousse'},
		{a: 'Rana esculenta', b: '105', c: 'Par Grand-Duc — CC BY 3.0, https://commons.wikimedia.org/w/index.php?curid=8288286', d: 'grenouille verte'},
		{a: 'Hyla arborea', b: '106', c: 'Par FelixReimann — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=2148874', d: 'rainette verte'},
		{a: 'Salamandra salamandra', b: '107', c: 'Par Didier Descouens — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=18182805', d: 'salamandre terrestre'},
		{a: 'Bombina variegata', b: '108', c: 'Par Rosenzweig — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=2377174', d: 'sonneur à ventre jaune'},
		{a: 'Triturus alpestris', b: '109', c: 'Par Joxerra Aihartza — FAL, https://commons.wikimedia.org/w/index.php?curid=11840993', d: 'triton alpestre'},
		{a: 'Triturus cristatus', b: '110', c: 'Par Christian Fischer — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=316387', d: 'triton crêté'},
		{a: 'Triturus helveticus', b: '111', c: 'Par André Chatroux — FAL, https://commons.wikimedia.org/w/index.php?curid=2569302', d: 'triton palmé'},
		{a: 'Triturus vulgaris', b: '112', c: 'Par Fritz Geller-Grimm — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=9855461', d: 'triton ponctué'},
		{a: 'Coronella austriaca', b: '113', c: 'Par Christian Fischer — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=4668787', d: 'coronelle lisse'},
		{a: 'Natrix natrix', b: '114', c: 'Par Lukas Jonaitis — CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=8399053', d: 'couleuvre à collier'},
		{a: 'Podarcis muralis', b: '115', c: 'Par Lucarelli — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=9878412', d: 'lézard des murailles'},
		{a: 'Lacerta agilis', b: '116', c: 'Par böhringer friedrich — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=2655257', d: 'lézard des souches'},
		{a: 'Zootoca vivipara', b: '117', c: 'Par <bdi>Charles J Sharp\n</bdi> — CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=57630280', d: 'lézard vivipare'},
		{a: 'Anguis fragilis', b: '118', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=22209', d: 'orvet fragile'},
		{a: 'Vipera berus', b: '119', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=124032', d: 'vipère péliade'}
	]);
var $author$project$BelgianReptiles$gameOverMsgs = {a6: 'Bravo ! Toute la mare est joyeuse grâce à toi !', bc: 'Pas mal. Mais le caméléon reste un peu confus, il va falloir s\'améliorer...', bh: 'Parfait ! Tu fais la fierté du monde reptilien.', bj: 'Oh non ! Ton serpent se mord la queue... Tu devrais réviser un peu.'};
var $author$project$BelgianReptiles$options = {a4: 'belgian_reptiles', a5: $author$project$BelgianReptiles$gameOverMsgs, bg: 'br'};
var $author$project$BelgianReptiles$binominalReptilesQuiz = {
	aF: $author$project$BelgianReptiles$options,
	aM: A3($author$project$Quiz$getImgWildlifeQuiz, $author$project$BelgianReptiles$data, 1, $author$project$BelgianReptiles$options),
	aU: 'BinominalReptiles',
	aW: 'Reptiles et amphibiens (binominal)'
};
var $author$project$BelgianTrees$data = _List_fromArray(
	[
		{a: 'Sorbus torminalis', b: '101', c: 'Par Andrew Dunn — CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=143088', d: 'Alisier torminal'},
		{a: 'Sorbus aria', b: '102', c: 'Par SBT — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=2409443', d: 'Alouchier'},
		{a: 'Crataegus monogyna', b: '103', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=72606', d: 'Aubépine à un style'},
		{a: 'Crataegus laevigata', b: '104', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=1411013', d: 'Aubépine à deux styles'},
		{a: 'Alnus incana', b: '105', c: 'Par Quadell — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=59826', d: 'Aulne blanc'},
		{a: 'Alnus glutinosa', b: '106', c: 'Par Carl Axel Magnus Lindman — Public domain, https://commons.wikimedia.org/w/index.php?curid=936543', d: 'Aulne glutineux'},
		{a: 'Betula alba', b: '107', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=9591', d: 'Bouleau pubescent'},
		{a: 'Betula pendula', b: '108', c: 'Par Jordgubbe — CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=207439', d: 'Bouleau verruqueux'},
		{a: 'Frangula alnus', b: '109', c: 'Par Walther Otto Müller — Public domain, https://commons.wikimedia.org/w/index.php?curid=255446', d: 'Bourdaine'},
		{a: 'Buxus sempervirens', b: '110', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=3429174', d: 'Buis'},
		{a: 'Prunus padus', b: '111', c: 'Par Nova — CC BY 2.5, https://commons.wikimedia.org/w/index.php?curid=752620', d: 'Cerisier à grappes'},
		{a: 'Prunus serotina', b: '112', c: 'Par Rasbak — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=163707', d: 'Cerisier tardif'},
		{a: 'Carpinus betulus', b: '113', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=1986567', d: 'Charme'},
		{a: 'Castanea sativa', b: '114', c: 'Par User:Kilom691 — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=2062908', d: 'Châtaignier'},
		{a: 'Quercus robur', b: '115', c: 'Par Adam Hauner — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=3264227', d: 'Chêne pédonculé'},
		{a: 'Quercus pubescens', b: '116', c: 'Par Etrusko25 — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=9067195', d: 'Chêne pubescent'},
		{a: 'Quercus rubra', b: '117', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=211084', d: 'Chêne rouge (d\'Amérique)'},
		{a: 'Quercus petraea', b: '118', c: 'Par Franz Eugen Köhler, <i>Köhler\'s Medizinal-Pflanzen</i> — Public domain, https://commons.wikimedia.org/w/index.php?curid=255443', d: 'Chêne sessile'},
		{a: 'Lonicera periclymenum', b: '119', c: 'Par Svdmolen — CC BY 2.5, https://commons.wikimedia.org/w/index.php?curid=2433572', d: 'Chèvrefeuille'},
		{a: 'Clematis vitalba', b: '120', c: 'Par Jeffdelonge — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=1673382', d: 'Clématite des haies'},
		{a: 'Cornus mas', b: '121', c: 'Par Jacob Sturm — Public domain, https://commons.wikimedia.org/w/index.php?curid=723978', d: 'Cornouiller mâle'},
		{a: 'Cornus sanguinea', b: '122', c: 'Par Johann Georg Sturm (Painter: Jacob Sturm) — Public domain, https://commons.wikimedia.org/w/index.php?curid=4111724', d: 'Cornouiller sanguin'},
		{a: 'Pseudotsuga menziesii', b: '123', c: 'Par Walter Siegmund — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=5931511', d: 'Douglas'},
		{a: 'Rosa canina', b: '124', c: 'Par Amédée Masclef — Public domain, https://commons.wikimedia.org/w/index.php?curid=5771133', d: 'Églantier'},
		{a: 'Picea abies', b: '125', c: 'Par User:Kilom691 — Public domain, https://commons.wikimedia.org/w/index.php?curid=2077456', d: 'Épicéa commun'},
		{a: 'Acer campestre', b: '126', c: 'Par Sten Porse — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=11688', d: 'Érable champêtre'},
		{a: 'Acer platanoides', b: '127', c: 'Par Carl Axel Magnus Lindman — Public domain, https://commons.wikimedia.org/w/index.php?curid=2062303', d: 'Érable plane'},
		{a: 'Acer pseudoplatanus', b: '128', c: 'Par user:MPF — Public domain, https://commons.wikimedia.org/w/index.php?curid=659089', d: 'Érable sycomore'},
		{a: 'Fraxinus excelsior', b: '129', c: 'Par Carl Axel Magnus Lindman — Public domain, https://commons.wikimedia.org/w/index.php?curid=185593', d: 'Frêne commun'},
		{a: 'Evonymus europaeus', b: '130', c: 'Par Jeffdelonge — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=2272334', d: 'Fusain d\'Europe'},
		{a: 'Juniperus communis', b: '131', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=19258', d: 'Genévrier commun'},
		{a: 'Ribes uva-crispa', b: '132', c: 'Par Uwe Hermann — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=130025', d: 'Groseillier épineux'},
		{a: 'Ribes nigrum', b: '133', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=269204', d: 'Groseillier noir'},
		{a: 'Ribes rubrum', b: '134', c: 'Par Hedwig Storch — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=7128470', d: 'Groseillier rouge'},
		{a: 'Fagus sylvatica', b: '135', c: 'Par Franz Eugen Köhler, <i>Köhler\'s Medizinal-Pflanzen</i> — Public domain, https://commons.wikimedia.org/w/index.php?curid=255332', d: 'Hêtre '},
		{a: 'Ilex aquifolium', b: '136', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=9360', d: 'Houx'},
		{a: 'Taxus baccata', b: '137', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=457198', d: 'If'},
		{a: 'Hedera helix', b: '138', c: 'Par kenraiz — Public domain, https://commons.wikimedia.org/w/index.php?curid=5197146', d: 'Lierre'},
		{a: 'Aesculus hippocastanum', b: '139', c: 'Par Ala z — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=2171768', d: 'Marronnier d\'Inde'},
		{a: 'Larix decidua', b: '140', c: 'Par Maurice Perry — CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=9798605', d: 'Mélèze d\'Europe'},
		{a: 'Prunus avium', b: '141', c: 'Par Prazak — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=773355', d: 'Merisier'},
		{a: 'Mespilus germanica', b: '142', c: 'Par Montilre — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=16013834', d: 'Néflier'},
		{a: 'Rhamnus cathartica', b: '143', c: 'Par Xemenendura — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=22254088', d: 'Nerprun purgatif'},
		{a: 'Corylus avellana', b: '144', c: 'Par User:Kilom691 — Public domain, https://commons.wikimedia.org/w/index.php?curid=2085896', d: 'Noisetier'},
		{a: 'Juglans regia', b: '145', c: 'Par Otto Wilhelm Thom&amp;eacute — Public domain, https://commons.wikimedia.org/w/index.php?curid=1834332', d: 'Noyer royal'},
		{a: 'Populus tremula', b: '146', c: 'Par Mav — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=6246230', d: 'Peuplier tremble'},
		{a: 'Pinus sylvestris', b: '147', c: 'Par Lee Carson on Flickr — CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=6315280', d: 'Pin sylvestre'},
		{a: 'Pyrus pyraster', b: '148', c: 'Par Rosser1954 — Public domain, https://commons.wikimedia.org/w/index.php?curid=3986077', d: 'Poirier sauvage'},
		{a: 'Malus sylvestris', b: '149', c: 'Par Sten Porse — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=604899', d: 'Pommier sauvage'},
		{a: 'Prunus spinosa', b: '150', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=1410818', d: 'Prunellier'},
		{a: 'Robinia pseudoacacia', b: '151', c: 'Par 4028mdk09 — CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=10413486', d: 'Robinier faux-acacia'},
		{a: 'Abies alba', b: '152', c: 'Par User:Kilom691 — Public domain, https://commons.wikimedia.org/w/index.php?curid=2066316', d: 'Sapin pectiné'},
		{a: 'Salix alba', b: '153', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=8799', d: 'Saule blanc'},
		{a: 'Salix caprea', b: '154', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=8793', d: 'Saule marsault'},
		{a: 'Sorbus aucuparia', b: '155', c: 'Par Walter Müller — Public domain, https://commons.wikimedia.org/w/index.php?curid=9182', d: 'Sorbier des oiseleurs'},
		{a: 'Sambucus racemosa', b: '156', c: 'Par [1] — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=5520', d: 'Sureau à grappes'},
		{a: 'Sambucus nigra', b: '157', c: 'Par Willow — CC BY 2.5, https://commons.wikimedia.org/w/index.php?curid=2643905', d: 'Sureau noir'},
		{a: 'Symphoricarpos albus', b: '158', c: 'Par ArtMechanic — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=202438', d: 'Symphorine '},
		{a: 'Tilia platyphyllos', b: '159', c: 'Par Johann Georg Sturm (Painter: Jacob Sturm) — Public domain, https://commons.wikimedia.org/w/index.php?curid=767495', d: 'Tilleul à grandes feuilles'},
		{a: 'Tilia cordata', b: '160', c: 'Par Franz Eugen Köhler, <i>Köhler\'s Medizinal-Pflanzen</i> — Public domain, https://commons.wikimedia.org/w/index.php?curid=255471', d: 'Tilleul à petites feuilles'},
		{a: 'Ligustrum vulgare', b: '161', c: 'Par Unknown artist — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=184962', d: 'Troène commun'},
		{a: 'Ligustrum ovalifolium', b: '162', c: 'Par MPF — CC BY 2.5, https://commons.wikimedia.org/w/index.php?curid=983054', d: 'Troène des haies'},
		{a: 'Viburnum lantana', b: '163', c: 'Par Jeffdelonge — CC-BY-SA-3.0, https://commons.wikimedia.org/w/index.php?curid=1651544', d: 'Viorne mancienne'},
		{a: 'Viburnum opulus', b: '164', c: 'Par Unknown artist — Public domain, https://commons.wikimedia.org/w/index.php?curid=9117', d: 'Viorne obier'}
	]);
var $author$project$BelgianTrees$gameOverMsgs = {a6: 'Bravo ! Tes connaissances semblent assez solides.', bc: 'L\'arbre de tes connaissances n\'a pas beaucoup de branches...', bh: 'Parfait ! Tu as atteint la majesté suprême des arbres.', bj: 'Oh non ! Tu as encore fait pleurer le saule...'};
var $author$project$BelgianTrees$options = {a4: 'belgian_trees', a5: $author$project$BelgianTrees$gameOverMsgs, bg: 'bt'};
var $author$project$BelgianTrees$binominalTreesQuiz = {
	aF: $author$project$BelgianTrees$options,
	aM: A3($author$project$Quiz$getImgWildlifeQuiz, $author$project$BelgianTrees$data, 1, $author$project$BelgianTrees$options),
	aU: 'BinominalTrees',
	aW: 'Arbres (binominal)'
};
var $author$project$BelgianWildlife$binominalQuizzList = _List_fromArray(
	[$author$project$BelgianBirds$binominalBirdsQuiz, $author$project$BelgianInsects$binominalInsectsQuiz, $author$project$BelgianMammals$binominalMammalsQuiz, $author$project$BelgianPlants$binominalPlantsQuiz, $author$project$BelgianReptiles$binominalReptilesQuiz, $author$project$BelgianTrees$binominalTreesQuiz, $author$project$BelgianMushrooms$binominalMushroomsQuiz]);
var $author$project$Quiz$Vernacular = 0;
var $author$project$BelgianBirds$belgianBirdsQuiz = {
	aF: $author$project$BelgianBirds$options,
	aM: A3($author$project$Quiz$getImgWildlifeQuiz, $author$project$BelgianBirds$data, 0, $author$project$BelgianBirds$options),
	aU: 'BelgianBirds',
	aW: 'Ornithologie'
};
var $author$project$BelgianInsects$belgianInsectsQuiz = {
	aF: $author$project$BelgianInsects$options,
	aM: A3($author$project$Quiz$getImgWildlifeQuiz, $author$project$BelgianInsects$data, 0, $author$project$BelgianInsects$options),
	aU: 'BelgianInsects',
	aW: 'Entomologie'
};
var $author$project$BelgianMammals$belgianMammalsQuiz = {
	aF: $author$project$BelgianMammals$options,
	aM: A3($author$project$Quiz$getImgWildlifeQuiz, $author$project$BelgianMammals$data, 0, $author$project$BelgianMammals$options),
	aU: 'BelgianMammals',
	aW: 'Mammalogie'
};
var $author$project$BelgianMushrooms$belgianMushroomsQuiz = {
	aF: $author$project$BelgianMushrooms$options,
	aM: A3($author$project$Quiz$getImgWildlifeQuiz, $author$project$BelgianMushrooms$data, 0, $author$project$BelgianMushrooms$options),
	aU: 'BelgianMushrooms',
	aW: 'Mycologie'
};
var $author$project$BelgianPlants$belgianPlantsQuiz = {
	aF: $author$project$BelgianPlants$options,
	aM: A3($author$project$Quiz$getImgWildlifeQuiz, $author$project$BelgianPlants$data, 0, $author$project$BelgianPlants$options),
	aU: 'BelgianPlants',
	aW: 'Botanique'
};
var $author$project$BelgianReptiles$belgianReptilesQuiz = {
	aF: $author$project$BelgianReptiles$options,
	aM: A3($author$project$Quiz$getImgWildlifeQuiz, $author$project$BelgianReptiles$data, 0, $author$project$BelgianReptiles$options),
	aU: 'BelgianReptiles',
	aW: 'Herpétologie'
};
var $author$project$BelgianTrees$belgianTreesQuiz = {
	aF: $author$project$BelgianTrees$options,
	aM: A3($author$project$Quiz$getImgWildlifeQuiz, $author$project$BelgianTrees$data, 0, $author$project$BelgianTrees$options),
	aU: 'BelgianTrees',
	aW: 'Dendrologie'
};
var $author$project$BelgianWildlife$vernacularQuizzList = _List_fromArray(
	[$author$project$BelgianBirds$belgianBirdsQuiz, $author$project$BelgianInsects$belgianInsectsQuiz, $author$project$BelgianMammals$belgianMammalsQuiz, $author$project$BelgianPlants$belgianPlantsQuiz, $author$project$BelgianReptiles$belgianReptilesQuiz, $author$project$BelgianTrees$belgianTreesQuiz, $author$project$BelgianMushrooms$belgianMushroomsQuiz]);
var $author$project$Main$allQuizz = _List_fromArray(
	[$author$project$BelgianWildlife$vernacularQuizzList, $author$project$BelgianWildlife$binominalQuizzList]);
var $author$project$Main$DisplayNextQuestion = function (a) {
	return {$: 0, a: a};
};
var $elm$random$Random$Generate = $elm$core$Basics$identity;
var $elm$random$Random$Seed = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$random$Random$next = function (_v0) {
	var state0 = _v0.a;
	var incr = _v0.b;
	return A2($elm$random$Random$Seed, ((state0 * 1664525) + incr) >>> 0, incr);
};
var $elm$random$Random$initialSeed = function (x) {
	var _v0 = $elm$random$Random$next(
		A2($elm$random$Random$Seed, 0, 1013904223));
	var state1 = _v0.a;
	var incr = _v0.b;
	var state2 = (state1 + x) >>> 0;
	return $elm$random$Random$next(
		A2($elm$random$Random$Seed, state2, incr));
};
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$random$Random$init = A2(
	$elm$core$Task$andThen,
	function (time) {
		return $elm$core$Task$succeed(
			$elm$random$Random$initialSeed(
				$elm$time$Time$posixToMillis(time)));
	},
	$elm$time$Time$now);
var $elm$random$Random$step = F2(
	function (_v0, seed) {
		var generator = _v0;
		return generator(seed);
	});
var $elm$random$Random$onEffects = F3(
	function (router, commands, seed) {
		if (!commands.b) {
			return $elm$core$Task$succeed(seed);
		} else {
			var generator = commands.a;
			var rest = commands.b;
			var _v1 = A2($elm$random$Random$step, generator, seed);
			var value = _v1.a;
			var newSeed = _v1.b;
			return A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$random$Random$onEffects, router, rest, newSeed);
				},
				A2($elm$core$Platform$sendToApp, router, value));
		}
	});
var $elm$random$Random$onSelfMsg = F3(
	function (_v0, _v1, seed) {
		return $elm$core$Task$succeed(seed);
	});
var $elm$random$Random$Generator = $elm$core$Basics$identity;
var $elm$random$Random$map = F2(
	function (func, _v0) {
		var genA = _v0;
		return function (seed0) {
			var _v1 = genA(seed0);
			var a = _v1.a;
			var seed1 = _v1.b;
			return _Utils_Tuple2(
				func(a),
				seed1);
		};
	});
var $elm$random$Random$cmdMap = F2(
	function (func, _v0) {
		var generator = _v0;
		return A2($elm$random$Random$map, func, generator);
	});
_Platform_effectManagers['Random'] = _Platform_createManager($elm$random$Random$init, $elm$random$Random$onEffects, $elm$random$Random$onSelfMsg, $elm$random$Random$cmdMap);
var $elm$random$Random$command = _Platform_leaf('Random');
var $elm$random$Random$generate = F2(
	function (tagger, generator) {
		return $elm$random$Random$command(
			A2($elm$random$Random$map, tagger, generator));
	});
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $elm$random$Random$peel = function (_v0) {
	var state = _v0.a;
	var word = (state ^ (state >>> ((state >>> 28) + 4))) * 277803737;
	return ((word >>> 22) ^ word) >>> 0;
};
var $elm$random$Random$int = F2(
	function (a, b) {
		return function (seed0) {
			var _v0 = (_Utils_cmp(a, b) < 0) ? _Utils_Tuple2(a, b) : _Utils_Tuple2(b, a);
			var lo = _v0.a;
			var hi = _v0.b;
			var range = (hi - lo) + 1;
			if (!((range - 1) & range)) {
				return _Utils_Tuple2(
					(((range - 1) & $elm$random$Random$peel(seed0)) >>> 0) + lo,
					$elm$random$Random$next(seed0));
			} else {
				var threshhold = (((-range) >>> 0) % range) >>> 0;
				var accountForBias = function (seed) {
					accountForBias:
					while (true) {
						var x = $elm$random$Random$peel(seed);
						var seedN = $elm$random$Random$next(seed);
						if (_Utils_cmp(x, threshhold) < 0) {
							var $temp$seed = seedN;
							seed = $temp$seed;
							continue accountForBias;
						} else {
							return _Utils_Tuple2((x % range) + lo, seedN);
						}
					}
				};
				return accountForBias(seed0);
			}
		};
	});
var $elm$core$Array$length = function (_v0) {
	var len = _v0.a;
	return len;
};
var $author$project$ArrayHelper$provideRandomElt = F2(
	function (msg, arr) {
		return A2(
			$elm$random$Random$generate,
			msg,
			A2(
				$elm$random$Random$int,
				0,
				$elm$core$Array$length(arr) - 1));
	});
var $author$project$Main$cmdNextQuestion = function (qas) {
	return A2($author$project$ArrayHelper$provideRandomElt, $author$project$Main$DisplayNextQuestion, qas);
};
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm_community$list_extra$List$Extra$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(
			A2($elm$core$List$drop, idx, xs));
	});
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Main$selectQuizzList = F2(
	function (all, index) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2($elm_community$list_extra$List$Extra$getAt, index, all));
	});
var $author$project$Main$defaultQuizzList = A2($author$project$Main$selectQuizzList, $author$project$Main$allQuizz, 0);
var $author$project$Quiz$emptyOptions = {
	a4: '',
	a5: {a6: '', bc: '', bh: '', bj: ''},
	bg: ''
};
var $author$project$Quiz$emptyDisplayableQuiz = {aF: $author$project$Quiz$emptyOptions, aM: $elm$core$Array$empty, aU: '', aW: ''};
var $author$project$Main$selectFirstQuiz = function (list) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Quiz$emptyDisplayableQuiz,
		A2($elm_community$list_extra$List$Extra$getAt, 0, list));
};
var $author$project$Main$defaultQuizz = $author$project$Main$selectFirstQuiz($author$project$Main$defaultQuizzList);
var $author$project$Quiz$emptyQuizItem = {
	at: _List_Nil,
	E: {ac: '', ao: '', bl: ''}
};
var $author$project$Main$init = function (_v0) {
	return _Utils_Tuple2(
		{
			S: '',
			ad: $author$project$Main$defaultQuizz.aU,
			o: $author$project$Quiz$emptyQuizItem,
			af: 20,
			T: 1,
			e: 0,
			U: false,
			J: false,
			K: 0,
			aF: $author$project$Main$defaultQuizz.aF,
			F: $author$project$Main$defaultQuizz.aM,
			ah: $author$project$Main$allQuizz,
			M: 0,
			v: $author$project$Main$defaultQuizz.aM,
			l: _Utils_Tuple2(0, 1),
			Y: $author$project$Main$defaultQuizzList
		},
		$author$project$Main$cmdNextQuestion($author$project$Main$defaultQuizz.aM));
};
var $author$project$Main$PickNextQuestion = {$: 1};
var $author$project$Main$WaitNext = 2;
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {aK: processes, aT: taggers};
	});
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.aK;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.aT);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Main$subscriptions = function (model) {
	return (model.e === 2) ? A2(
		$elm$time$Time$every,
		model.U ? 1000 : 2000,
		$elm$core$Basics$always($author$project$Main$PickNextQuestion)) : $elm$core$Platform$Sub$none;
};
var $author$project$Main$AskQuestion = 1;
var $author$project$Main$DisplayAnswers = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$DisplayCredits = 4;
var $author$project$Main$Exam = 1;
var $author$project$Main$Over = 3;
var $author$project$Main$SetOtherAnswers = function (a) {
	return {$: 3, a: a};
};
var $author$project$Main$Happy = 2;
var $author$project$Main$Proud = 3;
var $author$project$Main$Sad = 0;
var $elm$core$Basics$ge = _Utils_ge;
var $author$project$Main$calculateGameOverRatio = function (score) {
	var ratio = score.a / score.b;
	return (ratio === 1) ? 3 : ((ratio >= 0.75) ? 2 : ((ratio >= 0.5) ? 1 : 0));
};
var $elm$core$Array$filter = F2(
	function (isGood, array) {
		return $elm$core$Array$fromList(
			A3(
				$elm$core$Array$foldr,
				F2(
					function (x, xs) {
						return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
					}),
				_List_Nil,
				array));
	});
var $elm_community$list_extra$List$Extra$find = F2(
	function (predicate, list) {
		find:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (predicate(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$predicate = predicate,
						$temp$list = rest;
					predicate = $temp$predicate;
					list = $temp$list;
					continue find;
				}
			}
		}
	});
var $author$project$FHelper$ifThen = F3(
	function (b, f, a) {
		return b ? f(a) : a;
	});
var $elm$core$Elm$JsArray$map = _JsArray_map;
var $elm$core$Array$map = F2(
	function (func, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = function (node) {
			if (!node.$) {
				var subTree = node.a;
				return $elm$core$Array$SubTree(
					A2($elm$core$Elm$JsArray$map, helper, subTree));
			} else {
				var values = node.a;
				return $elm$core$Array$Leaf(
					A2($elm$core$Elm$JsArray$map, func, values));
			}
		};
		return A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A2($elm$core$Elm$JsArray$map, helper, tree),
			A2($elm$core$Elm$JsArray$map, func, tail));
	});
var $elm$core$Basics$neq = _Utils_notEqual;
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $elm$core$Elm$JsArray$appendN = _JsArray_appendN;
var $elm$core$Elm$JsArray$slice = _JsArray_slice;
var $elm$core$Array$appendHelpBuilder = F2(
	function (tail, builder) {
		var tailLen = $elm$core$Elm$JsArray$length(tail);
		var notAppended = ($elm$core$Array$branchFactor - $elm$core$Elm$JsArray$length(builder.h)) - tailLen;
		var appended = A3($elm$core$Elm$JsArray$appendN, $elm$core$Array$branchFactor, builder.h, tail);
		return (notAppended < 0) ? {
			i: A2(
				$elm$core$List$cons,
				$elm$core$Array$Leaf(appended),
				builder.i),
			f: builder.f + 1,
			h: A3($elm$core$Elm$JsArray$slice, notAppended, tailLen, tail)
		} : ((!notAppended) ? {
			i: A2(
				$elm$core$List$cons,
				$elm$core$Array$Leaf(appended),
				builder.i),
			f: builder.f + 1,
			h: $elm$core$Elm$JsArray$empty
		} : {i: builder.i, f: builder.f, h: appended});
	});
var $elm$core$Elm$JsArray$push = _JsArray_push;
var $elm$core$Elm$JsArray$singleton = _JsArray_singleton;
var $elm$core$Elm$JsArray$unsafeSet = _JsArray_unsafeSet;
var $elm$core$Array$insertTailInTree = F4(
	function (shift, index, tail, tree) {
		var pos = $elm$core$Array$bitMask & (index >>> shift);
		if (_Utils_cmp(
			pos,
			$elm$core$Elm$JsArray$length(tree)) > -1) {
			if (shift === 5) {
				return A2(
					$elm$core$Elm$JsArray$push,
					$elm$core$Array$Leaf(tail),
					tree);
			} else {
				var newSub = $elm$core$Array$SubTree(
					A4($elm$core$Array$insertTailInTree, shift - $elm$core$Array$shiftStep, index, tail, $elm$core$Elm$JsArray$empty));
				return A2($elm$core$Elm$JsArray$push, newSub, tree);
			}
		} else {
			var value = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!value.$) {
				var subTree = value.a;
				var newSub = $elm$core$Array$SubTree(
					A4($elm$core$Array$insertTailInTree, shift - $elm$core$Array$shiftStep, index, tail, subTree));
				return A3($elm$core$Elm$JsArray$unsafeSet, pos, newSub, tree);
			} else {
				var newSub = $elm$core$Array$SubTree(
					A4(
						$elm$core$Array$insertTailInTree,
						shift - $elm$core$Array$shiftStep,
						index,
						tail,
						$elm$core$Elm$JsArray$singleton(value)));
				return A3($elm$core$Elm$JsArray$unsafeSet, pos, newSub, tree);
			}
		}
	});
var $elm$core$Array$unsafeReplaceTail = F2(
	function (newTail, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		var originalTailLen = $elm$core$Elm$JsArray$length(tail);
		var newTailLen = $elm$core$Elm$JsArray$length(newTail);
		var newArrayLen = len + (newTailLen - originalTailLen);
		if (_Utils_eq(newTailLen, $elm$core$Array$branchFactor)) {
			var overflow = _Utils_cmp(newArrayLen >>> $elm$core$Array$shiftStep, 1 << startShift) > 0;
			if (overflow) {
				var newShift = startShift + $elm$core$Array$shiftStep;
				var newTree = A4(
					$elm$core$Array$insertTailInTree,
					newShift,
					len,
					newTail,
					$elm$core$Elm$JsArray$singleton(
						$elm$core$Array$SubTree(tree)));
				return A4($elm$core$Array$Array_elm_builtin, newArrayLen, newShift, newTree, $elm$core$Elm$JsArray$empty);
			} else {
				return A4(
					$elm$core$Array$Array_elm_builtin,
					newArrayLen,
					startShift,
					A4($elm$core$Array$insertTailInTree, startShift, len, newTail, tree),
					$elm$core$Elm$JsArray$empty);
			}
		} else {
			return A4($elm$core$Array$Array_elm_builtin, newArrayLen, startShift, tree, newTail);
		}
	});
var $elm$core$Array$appendHelpTree = F2(
	function (toAppend, array) {
		var len = array.a;
		var tree = array.c;
		var tail = array.d;
		var itemsToAppend = $elm$core$Elm$JsArray$length(toAppend);
		var notAppended = ($elm$core$Array$branchFactor - $elm$core$Elm$JsArray$length(tail)) - itemsToAppend;
		var appended = A3($elm$core$Elm$JsArray$appendN, $elm$core$Array$branchFactor, tail, toAppend);
		var newArray = A2($elm$core$Array$unsafeReplaceTail, appended, array);
		if (notAppended < 0) {
			var nextTail = A3($elm$core$Elm$JsArray$slice, notAppended, itemsToAppend, toAppend);
			return A2($elm$core$Array$unsafeReplaceTail, nextTail, newArray);
		} else {
			return newArray;
		}
	});
var $elm$core$Elm$JsArray$foldl = _JsArray_foldl;
var $elm$core$Array$builderFromArray = function (_v0) {
	var len = _v0.a;
	var tree = _v0.c;
	var tail = _v0.d;
	var helper = F2(
		function (node, acc) {
			if (!node.$) {
				var subTree = node.a;
				return A3($elm$core$Elm$JsArray$foldl, helper, acc, subTree);
			} else {
				return A2($elm$core$List$cons, node, acc);
			}
		});
	return {
		i: A3($elm$core$Elm$JsArray$foldl, helper, _List_Nil, tree),
		f: (len / $elm$core$Array$branchFactor) | 0,
		h: tail
	};
};
var $elm$core$Array$append = F2(
	function (a, _v0) {
		var aTail = a.d;
		var bLen = _v0.a;
		var bTree = _v0.c;
		var bTail = _v0.d;
		if (_Utils_cmp(bLen, $elm$core$Array$branchFactor * 4) < 1) {
			var foldHelper = F2(
				function (node, array) {
					if (!node.$) {
						var tree = node.a;
						return A3($elm$core$Elm$JsArray$foldl, foldHelper, array, tree);
					} else {
						var leaf = node.a;
						return A2($elm$core$Array$appendHelpTree, leaf, array);
					}
				});
			return A2(
				$elm$core$Array$appendHelpTree,
				bTail,
				A3($elm$core$Elm$JsArray$foldl, foldHelper, a, bTree));
		} else {
			var foldHelper = F2(
				function (node, builder) {
					if (!node.$) {
						var tree = node.a;
						return A3($elm$core$Elm$JsArray$foldl, foldHelper, builder, tree);
					} else {
						var leaf = node.a;
						return A2($elm$core$Array$appendHelpBuilder, leaf, builder);
					}
				});
			return A2(
				$elm$core$Array$builderToArray,
				true,
				A2(
					$elm$core$Array$appendHelpBuilder,
					bTail,
					A3(
						$elm$core$Elm$JsArray$foldl,
						foldHelper,
						$elm$core$Array$builderFromArray(a),
						bTree)));
		}
	});
var $elm$core$Array$sliceLeft = F2(
	function (from, array) {
		var len = array.a;
		var tree = array.c;
		var tail = array.d;
		if (!from) {
			return array;
		} else {
			if (_Utils_cmp(
				from,
				$elm$core$Array$tailIndex(len)) > -1) {
				return A4(
					$elm$core$Array$Array_elm_builtin,
					len - from,
					$elm$core$Array$shiftStep,
					$elm$core$Elm$JsArray$empty,
					A3(
						$elm$core$Elm$JsArray$slice,
						from - $elm$core$Array$tailIndex(len),
						$elm$core$Elm$JsArray$length(tail),
						tail));
			} else {
				var skipNodes = (from / $elm$core$Array$branchFactor) | 0;
				var helper = F2(
					function (node, acc) {
						if (!node.$) {
							var subTree = node.a;
							return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
						} else {
							var leaf = node.a;
							return A2($elm$core$List$cons, leaf, acc);
						}
					});
				var leafNodes = A3(
					$elm$core$Elm$JsArray$foldr,
					helper,
					_List_fromArray(
						[tail]),
					tree);
				var nodesToInsert = A2($elm$core$List$drop, skipNodes, leafNodes);
				if (!nodesToInsert.b) {
					return $elm$core$Array$empty;
				} else {
					var head = nodesToInsert.a;
					var rest = nodesToInsert.b;
					var firstSlice = from - (skipNodes * $elm$core$Array$branchFactor);
					var initialBuilder = {
						i: _List_Nil,
						f: 0,
						h: A3(
							$elm$core$Elm$JsArray$slice,
							firstSlice,
							$elm$core$Elm$JsArray$length(head),
							head)
					};
					return A2(
						$elm$core$Array$builderToArray,
						true,
						A3($elm$core$List$foldl, $elm$core$Array$appendHelpBuilder, initialBuilder, rest));
				}
			}
		}
	});
var $elm$core$Array$fetchNewTail = F4(
	function (shift, end, treeEnd, tree) {
		fetchNewTail:
		while (true) {
			var pos = $elm$core$Array$bitMask & (treeEnd >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var sub = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$end = end,
					$temp$treeEnd = treeEnd,
					$temp$tree = sub;
				shift = $temp$shift;
				end = $temp$end;
				treeEnd = $temp$treeEnd;
				tree = $temp$tree;
				continue fetchNewTail;
			} else {
				var values = _v0.a;
				return A3($elm$core$Elm$JsArray$slice, 0, $elm$core$Array$bitMask & end, values);
			}
		}
	});
var $elm$core$Array$hoistTree = F3(
	function (oldShift, newShift, tree) {
		hoistTree:
		while (true) {
			if ((_Utils_cmp(oldShift, newShift) < 1) || (!$elm$core$Elm$JsArray$length(tree))) {
				return tree;
			} else {
				var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, 0, tree);
				if (!_v0.$) {
					var sub = _v0.a;
					var $temp$oldShift = oldShift - $elm$core$Array$shiftStep,
						$temp$newShift = newShift,
						$temp$tree = sub;
					oldShift = $temp$oldShift;
					newShift = $temp$newShift;
					tree = $temp$tree;
					continue hoistTree;
				} else {
					return tree;
				}
			}
		}
	});
var $elm$core$Array$sliceTree = F3(
	function (shift, endIdx, tree) {
		var lastPos = $elm$core$Array$bitMask & (endIdx >>> shift);
		var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, lastPos, tree);
		if (!_v0.$) {
			var sub = _v0.a;
			var newSub = A3($elm$core$Array$sliceTree, shift - $elm$core$Array$shiftStep, endIdx, sub);
			return (!$elm$core$Elm$JsArray$length(newSub)) ? A3($elm$core$Elm$JsArray$slice, 0, lastPos, tree) : A3(
				$elm$core$Elm$JsArray$unsafeSet,
				lastPos,
				$elm$core$Array$SubTree(newSub),
				A3($elm$core$Elm$JsArray$slice, 0, lastPos + 1, tree));
		} else {
			return A3($elm$core$Elm$JsArray$slice, 0, lastPos, tree);
		}
	});
var $elm$core$Array$sliceRight = F2(
	function (end, array) {
		var len = array.a;
		var startShift = array.b;
		var tree = array.c;
		var tail = array.d;
		if (_Utils_eq(end, len)) {
			return array;
		} else {
			if (_Utils_cmp(
				end,
				$elm$core$Array$tailIndex(len)) > -1) {
				return A4(
					$elm$core$Array$Array_elm_builtin,
					end,
					startShift,
					tree,
					A3($elm$core$Elm$JsArray$slice, 0, $elm$core$Array$bitMask & end, tail));
			} else {
				var endIdx = $elm$core$Array$tailIndex(end);
				var depth = $elm$core$Basics$floor(
					A2(
						$elm$core$Basics$logBase,
						$elm$core$Array$branchFactor,
						A2($elm$core$Basics$max, 1, endIdx - 1)));
				var newShift = A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep);
				return A4(
					$elm$core$Array$Array_elm_builtin,
					end,
					newShift,
					A3(
						$elm$core$Array$hoistTree,
						startShift,
						newShift,
						A3($elm$core$Array$sliceTree, startShift, endIdx, tree)),
					A4($elm$core$Array$fetchNewTail, startShift, end, endIdx, tree));
			}
		}
	});
var $elm$core$Array$translateIndex = F2(
	function (index, _v0) {
		var len = _v0.a;
		var posIndex = (index < 0) ? (len + index) : index;
		return (posIndex < 0) ? 0 : ((_Utils_cmp(posIndex, len) > 0) ? len : posIndex);
	});
var $elm$core$Array$slice = F3(
	function (from, to, array) {
		var correctTo = A2($elm$core$Array$translateIndex, to, array);
		var correctFrom = A2($elm$core$Array$translateIndex, from, array);
		return (_Utils_cmp(correctFrom, correctTo) > 0) ? $elm$core$Array$empty : A2(
			$elm$core$Array$sliceLeft,
			correctFrom,
			A2($elm$core$Array$sliceRight, correctTo, array));
	});
var $author$project$ArrayHelper$removeByIndex = F2(
	function (arr, idx) {
		return A2(
			$elm$core$Array$append,
			A3($elm$core$Array$slice, 0, idx, arr),
			A3(
				$elm$core$Array$slice,
				idx + 1,
				$elm$core$Array$length(arr),
				arr));
	});
var $author$project$ArrayHelper$sliceByIndex = F2(
	function (arr, idx) {
		return _Utils_Tuple2(
			A2($elm$core$Array$get, idx, arr),
			A2($author$project$ArrayHelper$removeByIndex, arr, idx));
	});
var $author$project$Quiz$pickQuizQa = F3(
	function (randomIdx, qas, allQas) {
		var _v0 = A2($author$project$ArrayHelper$sliceByIndex, qas, randomIdx);
		var qa = _v0.a;
		var remainingQas = _v0.b;
		return _Utils_Tuple2(
			{
				at: _List_Nil,
				E: A2(
					$elm$core$Maybe$withDefault,
					{ac: '', ao: '', bl: ''},
					qa)
			},
			remainingQas);
	});
var $elm$random$Random$andThen = F2(
	function (callback, _v0) {
		var genA = _v0;
		return function (seed) {
			var _v1 = genA(seed);
			var result = _v1.a;
			var newSeed = _v1.b;
			var _v2 = callback(result);
			var genB = _v2;
			return genB(newSeed);
		};
	});
var $elm$random$Random$constant = function (value) {
	return function (seed) {
		return _Utils_Tuple2(value, seed);
	};
};
var $elm$core$Array$isEmpty = function (_v0) {
	var len = _v0.a;
	return !len;
};
var $elm_community$random_extra$Random$Array$choose = function (arr) {
	if ($elm$core$Array$isEmpty(arr)) {
		return $elm$random$Random$constant(
			_Utils_Tuple2($elm$core$Maybe$Nothing, arr));
	} else {
		var lastIndex = $elm$core$Array$length(arr) - 1;
		var gen = A2($elm$random$Random$int, 0, lastIndex);
		var front = function (i) {
			return A3($elm$core$Array$slice, 0, i, arr);
		};
		var back = function (i) {
			return _Utils_eq(i, lastIndex) ? $elm$core$Array$empty : A3($elm$core$Array$slice, i + 1, lastIndex + 1, arr);
		};
		return A2(
			$elm$random$Random$map,
			function (index) {
				return _Utils_Tuple2(
					A2($elm$core$Array$get, index, arr),
					A2(
						$elm$core$Array$append,
						front(index),
						back(index)));
			},
			gen);
	}
};
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm_community$random_extra$Random$Array$shuffle = function (arr) {
	if ($elm$core$Array$isEmpty(arr)) {
		return $elm$random$Random$constant(arr);
	} else {
		var helper = function (_v0) {
			var done = _v0.a;
			var remaining = _v0.b;
			return A2(
				$elm$random$Random$andThen,
				function (_v1) {
					var m_val = _v1.a;
					var shorter = _v1.b;
					if (m_val.$ === 1) {
						return $elm$random$Random$constant(
							_Utils_Tuple2(done, shorter));
					} else {
						var val = m_val.a;
						return helper(
							_Utils_Tuple2(
								A2($elm$core$List$cons, val, done),
								shorter));
					}
				},
				$elm_community$random_extra$Random$Array$choose(remaining));
		};
		return A2(
			$elm$random$Random$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $elm$core$Array$fromList),
			helper(
				_Utils_Tuple2(_List_Nil, arr)));
	}
};
var $author$project$ArrayHelper$provideShuffled = F2(
	function (msg, arr) {
		return A2(
			$elm$random$Random$generate,
			msg,
			$elm_community$random_extra$Random$Array$shuffle(arr));
	});
var $elm$core$Array$push = F2(
	function (a, array) {
		var tail = array.d;
		return A2(
			$elm$core$Array$unsafeReplaceTail,
			A2($elm$core$Elm$JsArray$push, a, tail),
			array);
	});
var $author$project$Main$setQuiz = F2(
	function (model, displayableQuiz) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{ad: displayableQuiz.aU, aF: displayableQuiz.aF, F: displayableQuiz.aM, v: displayableQuiz.aM}),
			$author$project$Main$cmdNextQuestion(displayableQuiz.aM));
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 5:
				var path = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: true}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var answer = msg.a;
				var hasWon = _Utils_eq(answer, model.o.E.ac);
				var score = _Utils_Tuple2(
					A3(
						$author$project$FHelper$ifThen,
						hasWon,
						$elm$core$Basics$add(1),
						model.l.a),
					model.l.b);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							S: answer,
							T: $author$project$Main$calculateGameOverRatio(score),
							e: 2,
							U: hasWon,
							l: score
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				return (_Utils_eq(model.l.b, model.af) && (model.K === 1)) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{e: 3}),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					_Utils_update(
						model,
						{
							S: '',
							e: 1,
							l: _Utils_Tuple2(model.l.a, model.l.b + 1)
						}),
					$author$project$Main$cmdNextQuestion(model.v));
			case 0:
				var randomIdx = msg.a;
				var _v1 = A3($author$project$Quiz$pickQuizQa, randomIdx, model.v, model.F);
				var chosen = _v1.a;
				var remaining = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							o: chosen,
							J: false,
							v: ($elm$core$Array$length(remaining) > 0) ? remaining : model.F
						}),
					A2(
						$author$project$ArrayHelper$provideShuffled,
						$author$project$Main$SetOtherAnswers,
						A2(
							$elm$core$Array$filter,
							function (val) {
								return !_Utils_eq(val.ao, chosen.E.ao);
							},
							model.F)));
			case 3:
				var arr = msg.a;
				var others = A3($elm$core$Array$slice, 0, 3, arr);
				var item = model.o;
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$ArrayHelper$provideShuffled,
						$author$project$Main$DisplayAnswers,
						A2($elm$core$Array$push, item.E, others)));
			case 4:
				var arr = msg.a;
				var item = model.o;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							o: _Utils_update(
								item,
								{
									at: $elm$core$Array$toList(
										A2(
											$elm$core$Array$map,
											function (qa) {
												return qa.ac;
											},
											arr))
								}),
							l: (model.e === 3) ? _Utils_Tuple2(0, 1) : model.l
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var lenght = $elm$core$List$length(model.ah);
				var newIndex = (_Utils_cmp(model.M + 1, lenght) < 0) ? (model.M + 1) : 0;
				var newQuizzList = A2($author$project$Main$selectQuizzList, model.ah, newIndex);
				var newModel = _Utils_update(
					model,
					{
						e: 0,
						M: newIndex,
						l: _Utils_Tuple2(0, 1),
						Y: newQuizzList
					});
				var newQuizz = $author$project$Main$selectFirstQuiz(newQuizzList);
				return A2($author$project$Main$setQuiz, newModel, newQuizz);
			case 7:
				var mode = msg.a;
				var newModel = _Utils_update(
					model,
					{e: 0, v: model.F});
				switch (mode) {
					case 'Infinite':
						return _Utils_Tuple2(
							_Utils_update(
								newModel,
								{K: 0}),
							$author$project$Main$cmdNextQuestion(newModel.v));
					case 'Exam':
						return _Utils_Tuple2(
							_Utils_update(
								newModel,
								{
									K: 1,
									l: _Utils_Tuple2(0, 1)
								}),
							$author$project$Main$cmdNextQuestion(newModel.v));
					case 'Credits':
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{e: 4}),
							$elm$core$Platform$Cmd$none);
					default:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				var quizName = msg.a;
				var newModel = _Utils_update(
					model,
					{
						e: 0,
						l: _Utils_Tuple2(0, 1)
					});
				var setNewQuiz = $author$project$Main$setQuiz(newModel);
				var displaybleQuiz = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Quiz$emptyDisplayableQuiz,
					A2(
						$elm_community$list_extra$List$Extra$find,
						function (quiz) {
							return _Utils_eq(quiz.aU, quizName);
						},
						model.Y));
				return setNewQuiz(displaybleQuiz);
		}
	});
var $author$project$Main$ChangeMode = function (a) {
	return {$: 7, a: a};
};
var $author$project$Main$ChangeQuiz = function (a) {
	return {$: 8, a: a};
};
var $author$project$Main$CheckAnswer = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$DisplayLoadedImg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$NextQuizzList = {$: 6};
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$json$Json$Encode$string = _Json_wrap;
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $elm$html$Html$div = _VirtualDom_node('div');
var $author$project$Main$getGameOverImg = F2(
	function (ratio, options) {
		return '/assets/img/' + (options.a4 + ('/resized/' + (options.bg + ('_' + (function () {
			switch (ratio) {
				case 0:
					return 'sad';
				case 1:
					return 'neutral';
				case 2:
					return 'happy';
				default:
					return 'proud';
			}
		}() + '.jpg')))));
	});
var $author$project$Main$getGameOverSentence = F2(
	function (ratio, msgs) {
		switch (ratio) {
			case 0:
				return msgs.bj;
			case 1:
				return msgs.bc;
			case 2:
				return msgs.a6;
			default:
				return msgs.bh;
		}
	});
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$core$Basics$not = _Basics_not;
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $author$project$Main$targetSrc = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'src']),
	$elm$json$Json$Decode$string);
var $author$project$Main$onLoadSrc = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'load',
		A2($elm$json$Json$Decode$map, tagger, $author$project$Main$targetSrc));
};
var $elm$html$Html$option = _VirtualDom_node('option');
var $elm$html$Html$p = _VirtualDom_node('p');
var $elm$html$Html$select = _VirtualDom_node('select');
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $author$project$Main$viewCredits = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('row')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('col')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mt-3')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Autoquizz')
						])),
					A2(
					$elm$html$Html$h2,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Par Loïc TRUCHOT - Étudiant '),
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href('https://www.guides-nature.be/')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('CNB')
								]))
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Licence Open Source et gratuite : '),
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href('https://creativecommons.org/licenses/by-nc/4.0/')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('CC-BY-NC-4.0')
								]))
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Cette application est destinée aux étudiants CNB et aux curieux de la nature, pour les aider à se familiariser avec la faune et la flore de Belgique.')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Toutes les illustrations ont pour source '),
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href('https://www.wikipedia.org/')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Wikipédia')
								])),
							$elm$html$Html$text('.')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Le code source en '),
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href('https://elm-lang.org/')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Elm')
								])),
							$elm$html$Html$text(' est disponible sur le '),
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href('https://github.com/ltruchot/cmt-belgian-wildlife-quizz')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('github/ltruchot')
								])),
							$elm$html$Html$text('.')
						]))
				]))
		]));
var $author$project$Main$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('container main-container')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group row m-1 mt-2')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-7 p-0')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$select,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-control form-control-sm'),
										$elm$html$Html$Events$onInput($author$project$Main$ChangeQuiz)
									]),
								A2(
									$elm$core$List$map,
									function (quiz) {
										return A2(
											$elm$html$Html$option,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$value(quiz.aU),
													$elm$html$Html$Attributes$selected(
													_Utils_eq(model.ad, quiz.aU))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(quiz.aW)
												]));
									},
									model.Y))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-5 p-0')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('input-group input-group-sm')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$select,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('custom-select'),
												$elm$html$Html$Events$onInput($author$project$Main$ChangeMode)
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$option,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$value('Infinite')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Infini')
													])),
												A2(
												$elm$html$Html$option,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$value('Exam')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Examen')
													])),
												A2(
												$elm$html$Html$option,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$value('Credits')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Crédits')
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-group-append')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$type_('button'),
														$elm$html$Html$Attributes$class('btn btn-outline-info'),
														$elm$html$Html$Events$onClick($author$project$Main$NextQuizzList)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														(!model.M) ? 'Ω' : 'a')
													]))
											]))
									]))
							]))
					])),
				(model.e === 4) ? $author$project$Main$viewCredits : A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('container p-0 m-0')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('row')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('col py-2 px-3 img-container')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$img,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$classList(
												_List_fromArray(
													[
														_Utils_Tuple2('img-h-230', true),
														_Utils_Tuple2('d-none', !model.J)
													])),
												$elm$html$Html$Attributes$title(model.o.E.bl),
												$elm$html$Html$Attributes$src(
												(model.e === 3) ? A2($author$project$Main$getGameOverImg, model.T, model.aF) : model.o.E.ao),
												$author$project$Main$onLoadSrc($author$project$Main$DisplayLoadedImg)
											]),
										_List_Nil),
										A2(
										$elm$html$Html$img,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$classList(
												_List_fromArray(
													[
														_Utils_Tuple2('img-h-230', true),
														_Utils_Tuple2('d-none', model.J)
													])),
												$elm$html$Html$Attributes$src('/assets/img/loader.svg')
											]),
										_List_Nil)
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('row', true),
										_Utils_Tuple2('d-none', model.e === 3)
									]))
							]),
						A2(
							$elm$core$List$map,
							function (answer) {
								return A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-6 col-md-3 p-1 min-h-80')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('h-100')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class(
															'custom-btn btn btn-lg btn-block no-transition h-100' + ((!((model.e === 2) || (model.e === 3))) ? ' btn-light' : (_Utils_eq(answer, model.o.E.ac) ? ' btn-success' : (((!_Utils_eq(answer, model.o.E.ac)) && _Utils_eq(answer, model.S)) ? ' btn-danger' : ' btn-light')))),
															$elm$html$Html$Attributes$disabled(model.e === 2),
															$elm$html$Html$Events$onClick(
															$author$project$Main$CheckAnswer(answer))
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(answer)
														]))
												]))
										]));
							},
							model.o.at)),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('row')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('col text-center p-3')
									]),
								function () {
									var _v0 = model.K;
									switch (_v0) {
										case 0:
											return _List_fromArray(
												[
													(model.e === 2) ? (model.U ? $elm$html$Html$text('Bien joué !') : $elm$html$Html$text('Perdu...')) : $elm$html$Html$text('')
												]);
										case 1:
											var score = $elm$core$String$fromInt(model.l.a) + ('/' + $elm$core$String$fromInt(model.af));
											return (model.e === 3) ? _List_fromArray(
												[
													A2(
													$elm$html$Html$h2,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Final score : ' + score)
														])),
													A2(
													$elm$html$Html$p,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(
															A2($author$project$Main$getGameOverSentence, model.T, model.aF.a5))
														])),
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('btn btn-primary d-block m-auto'),
															$elm$html$Html$Events$onClick(
															$author$project$Main$ChangeMode('Exam'))
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Recommencer')
														]))
												]) : _List_fromArray(
												[
													$elm$html$Html$text('Score : ' + score)
												]);
										default:
											return _List_fromArray(
												[
													$elm$html$Html$text('')
												]);
									}
								}())
							]))
					]))
			]));
};
var $author$project$Main$main = $elm$browser$Browser$element(
	{a9: $author$project$Main$init, bk: $author$project$Main$subscriptions, bm: $author$project$Main$update, bn: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main(
	$elm$json$Json$Decode$succeed(0))(0)}});}(this));